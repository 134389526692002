import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/i18n`;
export function useCampusI18nApi() {
  const { makeGetRequest } = useResource();

  const fetchI18n = async (locale: string): Promise<any> => makeGetRequest(`${baseUrl}/${locale}`);
  const fetchI18nDateTime = async (locale: string): Promise<any> => makeGetRequest(`${baseUrl}/${locale}/dateTime`);
  const fetchI18nNumberFormats = async (locale: string): Promise<any> => makeGetRequest(`${baseUrl}/${locale}/numbers`);

  const fetchLocalI18n = async (locale: string): Promise<any> => makeGetRequest(`/src/locales/${locale}.json`);
  const fetchLocalI18nDateTime = async (locale: string): Promise<any> =>
    makeGetRequest(`/src/locales/datetime-${locale}.json`);
  const fetchLocalI18nNumberFormats = async (locale: string): Promise<any> =>
    makeGetRequest(`/src/locales/numbers-${locale}.json`);

  return {
    fetchI18n,
    fetchI18nDateTime,
    fetchI18nNumberFormats,
    fetchLocalI18n,
    fetchLocalI18nDateTime,
    fetchLocalI18nNumberFormats
  };
}
