<template>
  <div class="flex flex-col">
    <slot> </slot>
    <span v-if="message && isTouched" class="inline-flex mt-0 text-sm text-danger-100">{{ message || '&nbsp;' }}</span>
  </div>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'NfErrorMessage'
}
</script>

<script setup lang="ts">
// import InlineMessage from "primevue/inlinemessage";
import { useFieldError, useIsFieldTouched } from 'vee-validate';
// @todo Deprecated / Legacy Code
const message = useFieldError();
const isTouched = useIsFieldTouched();

</script>

<style scoped></style>
