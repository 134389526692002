import type { SearchResult } from '@/types/contracts/generated/models/searchResult';
import type { SearchTypeDto } from '@/types/contracts/generated/models/searchTypeDto';
import type { Search } from '@/types/contracts/generated/models/search';
import type { ScoreDocDto } from '@/types/contracts/generated/models/scoreDocDto';

import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useSearch } from '@/composables/http/use-search';

export const useSearchStore = defineStore('search', () => {
  const search = ref<string>('');
  const searchResult = ref<SearchResult[]>();
  const page = ref<number>(1);
  const doc = ref<ScoreDocDto | undefined>();
  const hits = ref<number>(0);
  const created = ref<string | undefined>();

  const searchCategories = ref<SearchTypeDto[]>([]);

  const { getCategories, doSearch } = useSearch();

  function getSearchCategories() {
    getCategories().then((value) => (searchCategories.value = value.types as SearchTypeDto[]));
  }

  function handleSearch(search: Search) {
    return doSearch({
      ...search,
      doc: doc.value,
      results: undefined,
      page: page.value
    }).then((value) => {
      if (value.data.results) {
        if (value.data.page === 1) {
          searchResult.value = value.data.results as SearchResult[];
        } else {
          searchResult.value?.push(...(value.data.results as SearchResult[]));
        }
      } else {
        if (value.data.page === 1) {
          searchResult.value = [];
        }
      }
      page.value = value.data.page as number;
      doc.value = value.data.doc;
      hits.value = value.data.hits as number;
      created.value = value.data.created;
    });
  }

  function $reset() {
    search.value = '';
    searchResult.value = undefined;
    page.value = 1;
    doc.value = undefined;
    hits.value = 0;
    created.value = undefined;
  }

  return {
    search,
    searchCategories,
    getSearchCategories,
    handleSearch,
    searchResult,
    page,
    hits,
    doc,
    created,
    $reset
  };
});
