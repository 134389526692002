import type {
  AxiosInstance,
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseHeaders,
  AxiosResponseTransformer,
  CreateAxiosDefaults,
  Method,
  ResponseType
} from 'axios';

import axios from 'axios';
import { stringify } from 'qs';
import { getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';

import useAuth from '@/composables/use-auth';
import { parseResponseProblem } from '@/utils/problem/parse-response-problem';

export interface HttpRequestBaseOptions {
  responseTransformer?: AxiosResponseTransformer;
  params?: any;
  headers?: AxiosRequestHeaders | { [key: string]: string };
  responseType?: ResponseType;
}

export interface HttpGetOptions extends HttpRequestBaseOptions {}

export interface HttpDeleteOptions extends HttpRequestBaseOptions {}

export interface HttpRequestSendableOptions extends HttpRequestBaseOptions {
  requestTransformer?: AxiosRequestTransformer;
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export interface HttpPostOptions extends HttpRequestSendableOptions {}

export interface HttpPutOptions extends HttpRequestSendableOptions {}

const instanceMap = new Map<string, AxiosInstance>();

const concurrencyMap = new Map<string, Promise<any>>();

export function useResource(instanceName = 'default', config?: CreateAxiosDefaults) {
  if (!instanceMap.has(instanceName)) {
    instanceMap.set(instanceName, axios.create(config));
  }

  const instance = instanceMap.get(instanceName) as AxiosInstance;

  const makeGetRequestWithResponse = async (url: string, options: HttpGetOptions = {}) => {
    let resolve: (value: any) => void, reject: (value?: any) => void;
    if (concurrencyMap.has(url)) {
      return concurrencyMap.get(url);
    } else {
      const promise = new Promise((rs, rj) => {
        resolve = rs;
        reject = rj;
      });
      concurrencyMap.set(url, promise);
    }

    // Object.keys(newParams).forEach((k) => {
    //   if (newParams[k] instanceof DateTime) {
    //     newParams[k] = (newParams[k] as DateTime).toISODate();
    //   }
    // });

    const config = buildRequest(
      url,
      'GET',
      undefined,
      options
      //cancelController.signal
    );

    return doAxiosRequest(config, instance)
      .then((value) => {
        resolve(value);
        concurrencyMap.delete(url);
        return value;
      })
      .catch((err) => {
        reject(err);
        throw err;
      });
  };

  const makeGetRequest = async (url: string, options: HttpGetOptions = {}) => {
    const response = await makeGetRequestWithResponse(url, options);
    if (response?.data) {
      return response.data;
    } else {
      return null;
    }
  };

  const makePostRequest = async (url: string, data: any = null, options: HttpPostOptions = {}) => {
    return doAxiosRequest(buildRequest(url, 'POST', data, options), instance);
  };

  const makePutRequest = async (url: string, data: any = null, options: HttpPutOptions = {}) => {
    return doAxiosRequest(buildRequest(url, 'PUT', data, options), instance);
  };

  const makeDeleteRequest = async (url: string, options: HttpDeleteOptions = {}) => {
    return doAxiosRequest(buildRequest(url, 'DELETE', undefined, options), instance);
  };

  const makePatchRequest = async (url: string, options: HttpDeleteOptions = {}) => {
    return doAxiosRequest(buildRequest(url, 'PATCH', undefined, options), instance);
  };

  return {
    makeGetRequest,
    makePostRequest,
    makePutRequest,
    makeGetRequestWithResponse,
    makeDeleteRequest,
    makePatchRequest,
    instance
  };
}

function buildRequest(
  url: string,
  type: Method,
  data: any,
  options: HttpGetOptions | HttpPostOptions | HttpPutOptions | HttpDeleteOptions
): AxiosRequestConfig {
  const responseTransformerInt = (data: any, headers: AxiosResponseHeaders, status?: number): any =>
    includeProblemTransformer(data, headers, options.responseTransformer, status);

  return {
    method: type,
    url,
    data,
    transformRequest: (options as HttpPutOptions).requestTransformer,
    transformResponse: responseTransformerInt,
    headers: {
      ...options.headers
      //"Content-Type": "application/json",
    },
    params: options.params,
    paramsSerializer: {
      serialize: (params) => stringify(params, { arrayFormat: 'repeat' })
    },
    responseType: options.responseType,
    onDownloadProgress: (options as HttpPutOptions).onDownloadProgress,
    onUploadProgress: (options as HttpPutOptions).onUploadProgress
  };
}

async function doAxiosRequest(config: AxiosRequestConfig, instance: AxiosInstance): Promise<AxiosResponse<any>> {
  const { getAccessToken } = useAuth();
  const vueInstance = getCurrentInstance();
  const { locale } = vueInstance ? useI18n() : { locale: { value: 'de-DE' } };

  return await getAccessToken().then((value) => {
    if (value) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${value}`,
        'Accept-Language': locale.value
      };
    }
    return (
      instance
        .request(config)
        // .catch((reason) => {
        //     if (axios.isCancel(reason)) {
        //         //console.error("Request canceled", reason.message);
        //         return Promise.resolve();
        //     } else {
        //         throw reason;
        //     }
        // })
        .catch(parseResponseProblem)
    );
  });
}

function includeProblemTransformer(
  data: any,
  headers?: AxiosResponseHeaders,
  responseTransformer?: AxiosResponseTransformer,
  status?: number
): any {
  if (!headers || headers['content-type'] === 'application/problem+json') {
    return JSON.parse(data);
  }

  if (!headers['content-type']?.toLowerCase().includes('application/json')) {
    return data;
  }

  if (!!responseTransformer && data) {
    // @ts-ignore
    return responseTransformer(data, headers, status);
  }

  if (data) {
    return JSON.parse(data);
  }
}
