import type { ProfilzusatzDto } from '@/types/contracts/generated/models/profilzusatzDto';
import type { PersonDto } from '@/types/contracts/generated/models/personDto';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/person`;
export function usePerson() {
  const { makeGetRequest } = useResource();

  const getPerson = async (id: number): Promise<PersonDto> => makeGetRequest(`${baseUrl}/${id}`);

  const getPersonProfile = async (id: number): Promise<ProfilzusatzDto> => makeGetRequest(`${baseUrl}/profil/${id}`);

  return {
    getPerson,
    getPersonProfile
  };
}
