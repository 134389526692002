<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.size || 32"
    :height="props.size || 32"
    :fill="props.fill || 'currentColor'"
    viewBox="0 0 48 48"
  >
    <path
      d="M42,8a4.012,4.012,0,0,0-4-4H6A4.012,4.012,0,0,0,2,8V32a4.012,4.012,0,0,0,4,4H38a4.012,4.012,0,0,0,4-4V8M38,8,22,18,6,8H38m0,24H6V12L22,22,38,12Z"
      transform="translate(2 4)"
    />
  </svg>
</template>

<script lang="ts">
export interface IconProps {
  /**
   * Icon Size.
   */
  size?: number | string;

  /**
   * Icon Fill Color.
   */
  fill?: string;
}
</script>

<script lang="ts" setup>
// Define Component
const props = defineProps<IconProps>();
</script>
