<template>
  <!-- HTML Code -->
  <div class="error" :class="[props.message == false ? 'error-icon-only' : '']">
    <IconAlertCircleOutline width="32" height="32" />
    <div class="error-message" v-if="props.message != false">
      {{ props.message || $t('ToastMessages.ErrorCodes.Default') }}
    </div>
  </div>
</template>

<script lang="ts">
export interface ErrorFeedbackProps {
  /**
   * Custom error message, shown next of the error icon.
   */
  message?: string | null | false;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'ErrorFeedback'
}
</script>

<script lang="ts" setup>
import IconAlertCircleOutline from '~icons/mdi/alert-circle-outline';

// Define Component
const props = withDefaults(defineProps<ErrorFeedbackProps>(), {
  message: null
});
</script>

<style scoped>
.error {
  @apply flex flex-row max-w-xs mx-auto rounded items-center p-4 gap-3 text-sm font-semibold my-6;
  @apply bg-danger-10 text-danger-100;

  &.error-icon-only {
    @apply w-16 rounded-full;
  }
}
</style>
