import type { AxiosResponse } from 'axios';
import type { Search } from '@/types/contracts/generated/models/search';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/search`;
export function useSearch() {
  const { makeGetRequest, makePostRequest } = useResource();

  const getCategories = async (): Promise<Search> => makeGetRequest(`${baseUrl}`);

  const doSearch = async (data: Search): Promise<AxiosResponse<Search>> =>
    makePostRequest(`${baseUrl}`, data) as Promise<AxiosResponse<Search>>;

  return {
    getCategories,
    doSearch
  };
}
