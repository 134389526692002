import { UserManager } from 'oidc-client-ts';
import { ref } from 'vue';
import { settings } from '@/composables/auth/settings';

const userManager = ref<UserManager>(new UserManager(settings));

export default function () {
  /**
   * Initialize User Manager.
   */
  function init(): void {
    userManager.value = new UserManager(settings);
  }

  /**
   * Check if current user is logged in.
   * @returns
   */
  async function isLoggedIn(): Promise<boolean> {
    const user = await userManager.value.getUser();
    return user !== null && user.expired !== undefined && !user.expired;
  }

  /**
   * Get current users access token.
   * @returns
   */
  async function getAccessToken(): Promise<string> {
    const user = await userManager.value.getUser();
    return user ? user.access_token : '';
  }

  return {
    userManager,
    init,
    isLoggedIn,
    getAccessToken
  };
}
