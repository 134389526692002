<template>
  <PrimeSidebar
    v-model:visible="sidebarOpen"
    position="left"
    class="sidebar-small"
  >
    <div class="flex flex-col justify-between h-full pt-4 fom-mobile-side-navigation">
      <div class="flex flex-col gap-4 fom-mobile-side-navigation__items">
        <PageMobileMenuHeader
          :compact-profile="compactProfile"
          :mobile-profile-menu-open="mobileProfileMenuOpen"
          :toggle="toggleProfileMenu"
        />
        <div v-if="mobileProfileMenuOpen">
          <PageMobileMenuProfile :toggle="toggleProfileMenu" :mobile-profile-menu="mobileProfileMenu" />
        </div>
        <template v-else>
          <PrimePanelMenu v-if="mobileMenu" :model="mobileMenu" v-model:expandedKeys="expandedKeys">
            <template #item="{ item }">
              <div v-if="item.route" :class="[item.key?.split('_').length === 2 ? 'p-panelmenu-header-action' : '']">
                <RouterLink :to="item.route" class="p-menuitem-link" @click="sidebarOpen = false">
                  {{ item.label }}
                </RouterLink>
              </div>
              <div
                v-else
                class="flex justify-between"
                :class="[item.key?.split('_').length === 2 ? 'p-panelmenu-header-action' : 'p-menuitem-link']"
              >
                <span>{{ item.label }}</span>
                <IconChevronDown v-if="item.items && item.key && expandedKeys[item.key]" />
                <IconChevronRight v-else-if="item.items" class="pi pi-chevron-right" />
              </div>
            </template>
          </PrimePanelMenu>
          <div v-else class="flex flex-col gap-8 p-4 mt-4">
            <PrimeSkeleton class="!w-48 !h-4" />
            <PrimeSkeleton class="!w-56 !h-4" />
            <PrimeSkeleton class="!w-52 !h-4" />
            <PrimeSkeleton class="!w-40 !h-4" />
            <PrimeSkeleton class="!w-32 !h-4" />
          </div>
        </template>
      </div>
      <div class="p-4 fom-mobile-side-navigation__footer">
        <a class="flex items-center gap-2 logout" @click="logout">
          <IconLogout class="text-primary-700" />
          {{ $t('logout') }}
        </a>
      </div>
    </div>
  </PrimeSidebar>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'MobileSidebar'
}
</script>

<script setup lang="ts">
import type { User } from 'oidc-client-ts';

import IconChevronDown from '~icons/mdi/chevron-down';
import IconChevronRight from '~icons/mdi/chevron-right';
import IconLogout from '~icons/mdi/logout';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import PrimePanelMenu from 'primevue/panelmenu';
import PrimeSidebar from 'primevue/sidebar';
import PrimeSkeleton from 'primevue/skeleton';
import { onMounted, ref, watch } from 'vue';

import PageMobileMenuHeader from '@/components/sidebars/mobile-sidebar/PageMobileMenuHeader.vue';
import PageMobileMenuProfile from '@/components/sidebars/mobile-sidebar/PageMobileMenuProfile.vue';
import { useCampusUserApi } from '@/composables/http/use-campus-user-api';
import useAuth from '@/composables/use-auth';
import { usePersonStore } from '@/stores/person';
import { useMiscStore } from '@/stores/misc';
import { useSitemapStore } from '@/stores/sitemap';

// Stores
const { userManager } = useAuth();
const { postLogout } = useCampusUserApi();
const { compactProfile } = storeToRefs(usePersonStore());
const { mobileProfileMenuOpen } = storeToRefs(useMiscStore());
const { mobileMenu, expandedKeys, mobileProfileMenu } = storeToRefs(useSitemapStore());
const { width } = useWindowSize();

// States
const sidebarOpen = ref<boolean>(false);
const user = ref<User | null>(null);

/**
 * Component mounted
 */
onMounted(async () => {
  try {
    const result = await userManager.value.getUser();
    user.value = result;
  } catch (err) {
    console.error(err);
  }
});

/**
 * Watch Screen width
 */
watch(width, () => {
  sidebarOpen.value = false;
});

/**
 * Toggle Profile Menu
 */
function toggleProfileMenu() {
  mobileProfileMenuOpen.value = !mobileProfileMenuOpen.value;
}

/**
 * Open Sidebar
 */
function open() {
  sidebarOpen.value = true;
}

/**
 * close Sidebar
 */
function close() {
  sidebarOpen.value = false;
}

/**
 * Toggle Sidebar
 */
function toggle() {
  sidebarOpen.value = !sidebarOpen.value;
}

const logout = () => {
  postLogout().finally(() => {
    userManager.value.signoutRedirect();
  });
};

// Expose Component
defineExpose({
  isOpen: sidebarOpen,
  open,
  close,
  toggle
});
</script>

<style scoped>
.link-button {
  @apply text-primary-600;
}
:deep(.p-panelmenu [href="/node/6100"]) {
  @apply text-zinc-700;
}
:deep(.p-panelmenu .p-panelmenu-content .p-panelmenu-root-list) {
  margin: 0;
}
</style>
