import type {ExtendedMenuNodeDto} from '@/models/ExtendedMenuNodeDto';
import type {CourseFilterForm} from '@/types/contracts/generated/models/courseFilterForm';
import type {AxiosResponse} from 'axios';
import type {KursDto} from '@/types/contracts/generated/models/kursDto';
import type {CourseRegisterDeregisterForm} from '@/types/contracts/generated/models/courseRegisterDeregisterForm';
import type {DocentSearchDto} from '@/types/contracts/generated/models/docentSearchDto';
import type {CalendarDto} from '@/types/contracts/generated/models/calendarDto';
import type {RaumDto} from '@/types/contracts/generated/models/raumDto';
import type {DashboardDto} from '@/types/contracts/generated/models/dashboardDto';
import type {BaseDto} from '@/types/contracts/generated/models/baseDto';
import type {IdcardDto} from '@/types/contracts/generated/models/idcardDto';
import type {SepaDto} from '@/types/contracts/generated/models/sepaDto';
import {useResource} from '@/composables/http/use-resource';
import type {CourseFilterResultDto} from "@/types/contracts/generated/models/courseFilterResultDto";

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/user`;

export function useCampusUserApi() {
    const {makeGetRequest, makePostRequest, makeDeleteRequest} = useResource();

    const fetchMenu = async (): Promise<ExtendedMenuNodeDto[]> => makeGetRequest(`${baseUrl}/menu`);

    const fetchBase = async (): Promise<BaseDto> => makeGetRequest(`${baseUrl}/base`);

    const fetchCalendar = async (): Promise<CalendarDto> => makeGetRequest(`${baseUrl}/calendar`);

    const fetchRoom = async (id: number): Promise<RaumDto> => makeGetRequest(`${baseUrl}/raum/${id}`);

    const fetchDashboard = async (): Promise<DashboardDto> => makeGetRequest(`${baseUrl}/dashboard`);

    const fetchCourseFilter = async (): Promise<CourseFilterResultDto> => makeGetRequest(`${baseUrl}/courselist/filter`);

    const postFilter = async (filter: CourseFilterForm): Promise<AxiosResponse<KursDto[]>> =>
        makePostRequest(`${baseUrl}/courselist`, filter);

    const postRegister = async (register: CourseRegisterDeregisterForm) =>
        makePostRequest(`${baseUrl}/courseregister`, register);

    const postDeregister = async (deregister: CourseRegisterDeregisterForm) =>
        makePostRequest(`${baseUrl}/coursederegister`, deregister);

    const fetchDocentList = async (semester?: string, studienort?: string): Promise<DocentSearchDto> =>
        makeGetRequest(`${baseUrl}/docentlist`, {
            params: {
                semester,
                studienort
            }
        });

    const postLogout = async (): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/logout`);

    const getIdCard = async (): Promise<IdcardDto> => makeGetRequest(`${baseUrl}/idcard`);

    const postIdCardSepa = async (sepa: SepaDto): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/idcard`, sepa);

    const getIdCardSpending = async (dto: IdcardDto): Promise<AxiosResponse> =>
        makePostRequest(`${baseUrl}/idcard/ausgaben`, dto);

    const blockOrRemoveIdCard = async (): Promise<AxiosResponse> => makeDeleteRequest(`${baseUrl}/idcard`);

    const fetchSignupFilters = async (form: any): Promise<CourseFilterResultDto> =>
        makeGetRequest(`${baseUrl}/module_signup`, {params: form});
    const fetchSignupResults = async (form: any): Promise<AxiosResponse> =>
        makePostRequest(`${baseUrl}/module_signup_results`, form);

    return {
        fetchBase,
        fetchMenu,
        fetchCalendar,
        fetchRoom,
        fetchDashboard,
        fetchCourseFilter,
        postFilter,
        postRegister,
        postDeregister,
        fetchDocentList,
        postLogout,
        getIdCard,
        postIdCardSepa,
        blockOrRemoveIdCard,
        getIdCardSpending,
        fetchSignupFilters,
        fetchSignupResults
    };
}
