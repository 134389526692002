export interface Problem {}

export class Problem extends Error {
  protected _status: number;
  protected _title: string;
  protected _instance: string;
  protected _detail: string;
  protected _baseError: Error;
  constructor(message: string, status: number, title: string, instance: string, baseError: Error) {
    super(message);
    this._status = status;
    this._detail = message;
    this._title = title;
    this._instance = instance;
    this._baseError = baseError;
  }

  get status(): number {
    return this._status;
  }

  get title(): string {
    return this._title;
  }

  get instance(): string {
    return this._instance;
  }

  get detail(): string {
    return this._detail;
  }

  get baseError(): Error {
    return this._baseError;
  }
}
