import { createI18n } from 'vue-i18n';
import { useCampusI18nApi } from '@/composables/http/use-campus-i18n-api';
import preparePrimeVieLocales from '@/locales/primevue-locales';
import prepareYupLocales from '@/locales/yup-locales';

export default async function () {
  const mode = import.meta.env.MODE || 'production';

  // Create i18n Plugin
  const i18n = createI18n({
    legacy: false,
    locale: 'de-DE',
    fallbackLocale: 'de-DE'
  });

  // Set Locale Strings
  const campusApi = useCampusI18nApi();
  if (mode !== 'development') {
    i18n.global.setLocaleMessage('de-DE', await campusApi.fetchI18n('de-DE'));
    i18n.global.setDateTimeFormat('de-DE', await campusApi.fetchI18nDateTime('de-DE'));
    i18n.global.setNumberFormat('de-DE', await campusApi.fetchI18nNumberFormats('de-DE'));
  } else {
    i18n.global.setLocaleMessage('de-DE', await campusApi.fetchLocalI18n('de-DE'));
    i18n.global.setDateTimeFormat('de-DE', await campusApi.fetchLocalI18nDateTime('de-DE'));
    i18n.global.setNumberFormat('de-DE', await campusApi.fetchLocalI18nNumberFormats('de-DE'));
  }

  // Build Dependency Locales
  const localePrimeVue = preparePrimeVieLocales(i18n.global.t);
  const localeYup = prepareYupLocales(i18n.global.t);

  // Return
  return {
    i18nPlugin: i18n,
    localePrimeVue,
    localeYup
  };
}
