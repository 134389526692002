export default function (t: (key: string, options?: any) => string) {
  const locale = {
    startsWith: t('primevue.startsWith'),
    contains: t('primevue.contains'),
    notContains: t('primevue.notContains'),
    endsWith: t('primevue.endsWith'),
    equals: t('primevue.equals'),
    notEquals: t('primevue.notEquals'),
    noFilter: t('primevue.noFilter'),
    lt: t('primevue.lt'),
    lte: t('primevue.lte'),
    gt: t('primevue.gt'),
    gte: t('primevue.gte'),
    dateIs: t('primevue.dateIs'),
    dateIsNot: t('primevue.dateIsNot'),
    dateBefore: t('primevue.dateBefore'),
    dateAfter: t('primevue.dateAfter'),
    clear: t('primevue.clear'),
    apply: t('primevue.apply'),
    matchAll: t('primevue.matchAll'),
    matchAny: t('primevue.matchAny'),
    addRule: t('primevue.addRule'),
    removeRule: t('primevue.removeRule'),
    accept: t('primevue.accept'),
    reject: t('primevue.reject'),
    choose: t('primevue.choose'),
    upload: t('primevue.upload'),
    cancel: t('primevue.cancel'),
    completed: t('primevue.completed'),
    pending: t('primevue.pending'),
    fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    dayNames: [
      t('primevue.dayNames.sunday'),
      t('primevue.dayNames.monday'),
      t('primevue.dayNames.tuesday'),
      t('primevue.dayNames.wednesday'),
      t('primevue.dayNames.thursday'),
      t('primevue.dayNames.friday'),
      t('primevue.dayNames.saturday')
    ],
    dayNamesShort: [
      t('primevue.dayNamesShort.sunday'),
      t('primevue.dayNamesShort.monday'),
      t('primevue.dayNamesShort.tuesday'),
      t('primevue.dayNamesShort.wednesday'),
      t('primevue.dayNamesShort.thursday'),
      t('primevue.dayNamesShort.friday'),
      t('primevue.dayNames.saturday')
    ],
    dayNamesMin: [
      t('primevue.dayNamesMin.sunday'),
      t('primevue.dayNamesMin.monday'),
      t('primevue.dayNamesMin.tuesday'),
      t('primevue.dayNamesMin.wednesday'),
      t('primevue.dayNamesMin.thursday'),
      t('primevue.dayNamesMin.friday'),
      t('primevue.dayNamesMin.saturday')
    ],
    monthNames: [
      t('primevue.monthNames.january'),
      t('primevue.monthNames.february'),
      t('primevue.monthNames.march'),
      t('primevue.monthNames.april'),
      t('primevue.monthNames.may'),
      t('primevue.monthNames.june'),
      t('primevue.monthNames.july'),
      t('primevue.monthNames.august'),
      t('primevue.monthNames.september'),
      t('primevue.monthNames.october'),
      t('primevue.monthNames.november'),
      t('primevue.monthNames.december')
    ],
    monthNamesShort: [
      t('primevue.monthNamesShort.january'),
      t('primevue.monthNamesShort.february'),
      t('primevue.monthNamesShort.march'),
      t('primevue.monthNamesShort.april'),
      t('primevue.monthNamesShort.may'),
      t('primevue.monthNamesShort.june'),
      t('primevue.monthNamesShort.july'),
      t('primevue.monthNamesShort.august'),
      t('primevue.monthNamesShort.september'),
      t('primevue.monthNamesShort.october'),
      t('primevue.monthNamesShort.november'),
      t('primevue.monthNamesShort.december')
    ],
    chooseYear: t('primevue.chooseYear'),
    chooseMonth: t('primevue.chooseMonth'),
    chooseDate: t('primevue.chooseDate'),
    prevDecade: t('primevue.prevDecade'),
    nextDecade: t('primevue.nextDecade'),
    prevYear: t('primevue.prevYear'),
    nextYear: t('primevue.nextYear'),
    prevMonth: t('primevue.prevMonth'),
    nextMonth: t('primevue.nextMonth'),
    prevHour: t('primevue.prevHour'),
    nextHour: t('primevue.nextHour'),
    prevMinute: t('primevue.prevMinute'),
    nextMinute: t('primevue.nextMinute'),
    prevSecond: t('primevue.prevSecond'),
    nextSecond: t('primevue.nextSecond'),
    am: t('primevue.am'),
    pm: t('primevue.pm'),
    today: t('primevue.today'),
    weekHeader: t('primevue.weekHeader'),
    firstDayOfWeek: parseInt(t('primevue.firstDayOfWeek')),
    dateFormat: t('primevue.dateFormat'),
    weak: t('primevue.weak'),
    medium: t('primevue.medium'),
    strong: t('primevue.strong'),
    passwordPrompt: t('primevue.passwordPrompt'),
    emptyFilterMessage: t('primevue.emptyFilterMessage'),
    searchMessage: t('primevue.searchMessage'),
    selectionMessage: t('primevue.selectionMessage'),
    emptySelectionMessage: t('primevue.emptySelectionMessage'),
    emptySearchMessage: t('primevue.emptySearchMessage'),
    emptyMessage: t('primevue.emptyMessage'),
    aria: {
      trueLabel: t('primevue.aria.trueLabel'),
      falseLabel: t('primevue.aria.falseLabel'),
      nullLabel: t('primevue.aria.nullLabel'),
      star: t('primevue.aria.star'),
      stars: t('primevue.aria.stars'),
      selectAll: t('primevue.aria.selectAll'),
      unselectAll: t('primevue.aria.unselectAll'),
      close: t('primevue.aria.close'),
      previous: t('primevue.aria.previous'),
      next: t('primevue.aria.next'),
      navigation: t('primevue.aria.navigation'),
      scrollTop: t('primevue.aria.scrollTop'),
      moveTop: t('primevue.aria.moveTop'),
      moveUp: t('primevue.aria.moveUp'),
      moveDown: t('primevue.aria.moveDown'),
      moveBottom: t('primevue.aria.moveBottom'),
      moveToTarget: t('primevue.aria.moveToTarget'),
      moveToSource: t('primevue.aria.moveToSource'),
      moveAllToTarget: t('primevue.aria.moveAllToTarget'),
      moveAllToSource: t('primevue.aria.moveAllToSource'),
      pageLabel: t('primevue.aria.pageLabel'),
      firstPageLabel: t('primevue.aria.firstPageLabel'),
      lastPageLabel: t('primevue.aria.lastPageLabel'),
      nextPageLabel: t('primevue.aria.nextPageLabel'),
      prevPageLabel: t('primevue.aria.prevPageLabel'),
      rowsPerPageLabel: t('primevue.aria.rowsPerPageLabel'),
      jumpToPageDropdownLabel: t('primevue.aria.jumpToPageDropdownLabel'),
      jumpToPageInputLabel: t('primevue.aria.jumpToPageInputLabel'),
      selectRow: t('primevue.aria.selectRow'),
      unselectRow: t('primevue.aria.unselectRow'),
      expandRow: t('primevue.aria.expandRow'),
      collapseRow: t('primevue.aria.collapseRow'),
      showFilterMenu: t('primevue.aria.showFilterMenu'),
      hideFilterMenu: t('primevue.aria.hideFilterMenu'),
      filterOperator: t('primevue.aria.filterOperator'),
      filterConstraint: t('primevue.aria.filterConstraint'),
      editRow: t('primevue.aria.editRow'),
      saveEdit: t('primevue.aria.saveEdit'),
      cancelEdit: t('primevue.aria.cancelEdit'),
      listView: t('primevue.aria.listView'),
      gridView: t('primevue.aria.gridView'),
      slide: t('primevue.aria.slide'),
      slideNumber: t('primevue.aria.slideNumber'),
      zoomImage: t('primevue.aria.zoomImage'),
      zoomIn: t('primevue.aria.zoomIn'),
      zoomOut: t('primevue.aria.zoomOut'),
      rotateRight: t('primevue.aria.rotateRight'),
      rotateLeft: t('primevue.aria.rotateLeft')
    }
  };

  return locale;
}
