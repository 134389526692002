import type { KompaktProfilViewModel } from '@/types/contracts/generated/models/kompaktProfilViewModel';
import type { PersonenProfilViewModel } from '@/types/contracts/generated/models/personenProfilViewModel';
import type { KontaktdatenViewModel } from '@/types/contracts/generated/models/kontaktdatenViewModel';
import type { AxiosResponse } from 'axios';
import type { FotoUploadModel } from '@/types/contracts/generated/models/fotoUploadModel';
import type { OeffentlichesProfilKonfigurationViewModel } from '@/types/contracts/generated/models/oeffentlichesProfilKonfigurationViewModel';
import type { NutzungsbedingungLiteraturbestaendeViewModel } from '@/types/contracts/generated/models/nutzungsbedingungLiteraturbestaendeViewModel';
import type { CountryViewModel } from '@/types/contracts/generated/models/countryViewModel';
import type { AddressViewModel } from '@/types/contracts/generated/models/addressViewModel';
import type { KontaktdatenKommunikationsViewModel } from '@/types/contracts/generated/models/kontaktdatenKommunikationsViewModel';
import type { BescheinigungenViewModel } from '@/types/contracts/generated/models/bescheinigungenViewModel';
import type { PublicProfileConfigurationModel } from '@/models/public-profile-configuration.model';

import { useResource } from '@/composables/http/use-resource';
import type {DiplomaDocumentViewModel} from "@/types/contracts/generated/models/diplomaDocumentViewModel";
import type {DokumentenDownloadViewModel} from "@/types/contracts/generated/models/dokumentenDownloadViewModel";

const baseUrl = `${import.meta.env.VITE_API_CAMPUS_BCW}`;
// const oc5Staging = 'https://services-haproxy.bcw-intern.local/onlinecampusbackendservicestaging';

export function useBcwServices() {
  const { makeGetRequest, makePostRequest, makePatchRequest, makePutRequest, makeDeleteRequest } = useResource();

  const getKompaktProfil = async (): Promise<KompaktProfilViewModel> => makeGetRequest(`${baseUrl}/stammdaten/kompaktprofil`);
  // const getKompaktProfil = async (): Promise<KompaktProfilViewModel> => makeGetRequest(`${oc5Staging}/stammdaten/kompaktprofilnf`);
  const getProfil = async (): Promise<PersonenProfilViewModel> => makeGetRequest(`${baseUrl}/stammdaten/profil`);
  const getContactInformation = async (): Promise<KontaktdatenViewModel> => makeGetRequest(`${baseUrl}/meinprofil`);
  const getStammdatenPhoto = async (): Promise<string> => makeGetRequest(`${baseUrl}/stammdaten/photo`);
  const postStammdatenPhoto = async (form: FotoUploadModel): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/stammdaten/photo`, form);
  const getOeffentlichesProfilKonfig = async (): Promise<OeffentlichesProfilKonfigurationViewModel> =>
    makeGetRequest(`${baseUrl}/profilkonfiguration/oeffentlichesprofil`);
  const postOeffentlichesProfilKonfig = async (
    profil: OeffentlichesProfilKonfigurationViewModel
  ): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/profilkonfiguration/oeffentlichesprofil`, profil);
  const checkTourVisited = async (tourId: number): Promise<boolean> =>
    makeGetRequest(`${baseUrl}/tour-besucht`, { params: { tourId: tourId } });
  const setTourVisited = async (tourId: number): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/tour-besucht`, null, { params: { tourId: tourId } });
  //@ts-ignore
  const getLiteraryTermsOfUse = async (): Promise<NutzungsbedingungLiteraturbestaendeViewModel> =>
    makeGetRequest(`${baseUrl}/rechtliche-bedingungen/nutzungsbedingung/literaturbestaende`, {
      headers: { Accept: 'application/json' }
    });
  const acceptLiteraryTermsOfUse = async (): Promise<AxiosResponse> =>
    makePatchRequest(`${baseUrl}/rechtliche-bedingungen/nutzungsbedingung/literaturbestaende`);
  //@ts-ignore
  const getTermsOfUse = async (domainId: number): Promise<any> =>
    makeGetRequest(`${baseUrl}/dokumente-nutzungsbedingungen/nutzungsbedingung/dokumente/${domainId}`, {
      headers: { Accept: 'application/json' }
    });
  const acceptTermsOfUse = async (domainId: number, data: any): Promise<any> =>
    makePutRequest(`${baseUrl}/nutzungsbedingung/dokumente-bestaetigen/${domainId}`, data);
  //@ts-ignore
  const getContentDocument = async (eloId: number): Promise<ReadableStream> =>
    makeGetRequest(`${baseUrl}/content-dokument/${eloId}`, { headers: { Accept: 'application/json' } });
  //@ts-ignore
  const getPersonDocument = async (eloId: number): Promise<ReadableStream> =>
    makeGetRequest(`${baseUrl}/person-dokument/${eloId}`, { headers: { Accept: 'application/json' } });
  //@ts-ignore
  const getExaminationRegulations = async (documentId: number): Promise<ReadableStream> =>
    makeGetRequest(`${baseUrl}/pruefungsordnungen/dokument/${documentId}`, { headers: { Accept: 'application/json' } });
  const getCountries = async (): Promise<CountryViewModel> => makeGetRequest(`${baseUrl}/country`);
  const getAddressById = async (addressId: number): Promise<AddressViewModel> =>
    makeGetRequest(`${baseUrl}/address/${addressId}`);
  const postAddress = async (addressModel: AddressViewModel): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/address`, addressModel);
  const putAddress = async (addressId: number, addressModel: AddressViewModel): Promise<AxiosResponse> =>
    makePutRequest(`${baseUrl}/address`, addressModel);
  const deleteAddress = async (addressId: number): Promise<AxiosResponse> =>
    makeDeleteRequest(`${baseUrl}/address/${addressId}`);

  const postContactData = async (phoneNumberModel: KontaktdatenKommunikationsViewModel): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/kommunikation`, phoneNumberModel);
  const putContactData = async (
    phoneNumberId: number,
    phoneNumberModel: KontaktdatenKommunikationsViewModel
  ): Promise<AxiosResponse> => makePutRequest(`${baseUrl}/kommunikation`, phoneNumberModel);
  const deleteContactData = async (phoneNumberId: number): Promise<AxiosResponse> =>
    makeDeleteRequest(`${baseUrl}/kommunikation/${phoneNumberId}`);

  //@ts-ignore
  const getDocuments = async (typeOfCertificate: string): Promise<BescheinigungenViewModel> =>
    makeGetRequest(`${baseUrl}/bescheinigungen/${typeOfCertificate}`, { headers: { Accept: 'application/json' } });
  //@ts-ignore
  const downloadDocument = async (eloId: number): Promise<BescheinigungenViewModel> =>
    makeGetRequest(`${baseUrl}/person-dokument/${eloId}`, { headers: { Accept: 'application/json' } });
  const courseInformation = async (): Promise<any> => makeGetRequest(`${baseUrl}/stammdaten/studieninformationenAlleCampusStandorte`);
  // const courseInformation = async (): Promise<any> => makeGetRequest(`${oc5Staging}/stammdaten/studieninformationenAlleCampusStandorte`);

  const seminarInformation = async (): Promise<any> => makeGetRequest(`${baseUrl}/stammdaten/studieninformationenAlleCampusStandorte`);

  const getProcessingDataStatus = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/bankaccount/gettrackchangeofaccountdata`);
  const getBankAccountData = async (): Promise<any> => makeGetRequest(`${baseUrl}/bankaccount/getIBAN`);
  // const getBankAccountData = async (): Promise<any> => makeGetRequest(`${oc5Staging}/bankaccount/getIBAN`);
  const postBankAccountData = async (payload: any, iban: string): Promise<AxiosResponse> => makePutRequest(`${baseUrl}/bankaccount/${iban}`, payload);
  // const postBankAccountData = async (payload: any, iban: string): Promise<AxiosResponse> => makePutRequest(`${oc5Staging}/bankaccount/${iban}`, payload);

  const getDataUsage = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/rechtliche-bedingungen/datennutzung/status`);
  const getConsentStatus = async (): Promise<any> => makeGetRequest(`${baseUrl}/werbeeinwilligung/status`);
  const updateConsentStatus = async (payload: any): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/werbeeinwilligung/update`, payload);

  const getOffice365 = async (): Promise<any> => makeGetRequest(`${baseUrl}/optinoffice365`);
  const postOffice365 = async (payload: any): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/optinoffice365`, payload);
  // const postOffice365 = async (payload: any): Promise<AxiosResponse> => makePostRequest(`${oc5Staging}/optinoffice365`, payload);
  const deactivateOptin365 = async (): Promise<any> => makePostRequest(`${baseUrl}/optoutoffice365`);
  // const deactivateOptin365 = async (): Promise<any> => makePostRequest(`${oc5Staging}/optoutoffice365`);

  const getProfileConfig = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/profilkonfiguration/oeffentlichesprofil`);
  const postProfileConfig = async (payload: PublicProfileConfigurationModel): Promise<any> =>
    makePostRequest(`${baseUrl}/profilkonfiguration/oeffentlichesprofilvollstaendig`, payload);

  const getPublicProfile = async (personId: number): Promise<any> =>
    makeGetRequest(`${baseUrl}/stammdaten/oeffentlichesprofil/${personId}`);

  //@ts-ignore
  const getInvoices = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/rechnungen`, { headers: { Accept: 'application/json' } });
  const postInvoice = async (dunningNr: number): Promise<any> =>
    makePostRequest(`${baseUrl}/rechnungen/${dunningNr}`, {});

  //@ts-ignore
  const getDunningLettersApi = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/rechnungen/dunningLetters`, { headers: { Accept: 'application/json' } });
  const postDuningLetterApi = async (invoiceId: number): Promise<any> =>
    makePostRequest(`${baseUrl}/rechnungen/setDunningStatus/${invoiceId}`, {});

  //@ts-igonre
  const getPaymentAgreementsApi = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/rechnungen/paymentAgreements`, { headers: { Accept: 'application/json' } });
  const postPaymentAgreement = async (paymentAgreementNumber: number): Promise<any> =>
    makePostRequest(`${baseUrl}/rechnungen/setPaymentAgreementStatus/${paymentAgreementNumber}`, {});

  //@ts-ignore
  const getExmatriculationCertificates = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/bescheinigungen/getExmatrikulations`, { headers: { Accept: 'application/json' } });
  //@ts-ignore
  const getAttendanceCertificates = async (): Promise<any> => makeGetRequest(`${baseUrl}/bescheinigungen/getCertificatesOfAttendance`, { headers: { Accept: 'application/json' } });
  // const getAttendanceCertificates = async (): Promise<any> => makeGetRequest(`${oc5Staging}/getCertificatesOfAttendance`, { headers: { Accept: 'application/json' } });

  const getDegreePrograms = async (): Promise<any> => makeGetRequest(`${baseUrl}/stammdaten/getDegreePrograms`);
  const transcriptOfRecords = async (payload: any): Promise<any> =>
    makePostRequest(`${baseUrl}/stammdaten/requestTranscriptOfRecords`, payload);

  const getWebsiteCertificates = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/bescheinigungen/getCertificates`, { headers: { Accept: 'application/json' } });

  // const semesterSpeaker = async (): Promise<any> => makeGetRequest(`${oc5Staging}/semesterspeaker`);
  const semesterSpeaker = async (): Promise<any> => makeGetRequest(`${baseUrl}/semesterspeaker`);

  const showTermsOfUse = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/rechtliche-bedingungen/nutzungsbedingung/anzeigen`);
  const aceptTermsOfUse = async (): Promise<any> =>
    makePostRequest(`${baseUrl}/rechtliche-bedingungen/nutzungsbedingung/zustimmen`);

  //@ts-ignore
  const showUserAgreements = async (): Promise<any> =>
    makeGetRequest(`${baseUrl}/rechtliche-bedingungen/extendedTermsOfUse/show`, {
      headers: { Accept: 'application/json' }
    });
  const acceptUserAgreements = async (): Promise<any> =>
    makePostRequest(`${baseUrl}/rechtliche-bedingungen/extendedTermsOfUse/agree`);

  const getRequiredDocuments = async (): Promise<any> => makeGetRequest(`${baseUrl}/erforderlichedokumente`, { headers: { Accept: 'application/json' } });
  const postRequiredDocument = async (payload: any): Promise<any> =>
    makePostRequest(`${baseUrl}/erforderlichedokumente/upload-erforderlichedokumente`, payload);

  const getDiplomaDocuments = async (): Promise<DiplomaDocumentViewModel[]> => makeGetRequest(`${baseUrl}/diplomadocument`, { headers: { Accept: 'application/json' } });
  const getDiplomaDocument = async (id: number): Promise<DokumentenDownloadViewModel> => makeGetRequest(`${baseUrl}/diplomadocument/${id}`, { headers: { Accept: 'application/json' } });

  return {
    getKompaktProfil,
    getProfil,
    getContactInformation,
    getStammdatenPhoto,
    postStammdatenPhoto,
    getOeffentlichesProfilKonfig,
    postOeffentlichesProfilKonfig,
    checkTourVisited,
    setTourVisited,
    getLiteraryTermsOfUse,
    acceptLiteraryTermsOfUse,
    getTermsOfUse,
    acceptTermsOfUse,
    getContentDocument,
    getPersonDocument,
    getExaminationRegulations,
    getCountries,
    getAddressById,
    postAddress,
    putAddress,
    deleteAddress,
    postContactData,
    putContactData,
    deleteContactData,
    getDocuments,
    downloadDocument,
    courseInformation,
    seminarInformation,
    getProcessingDataStatus,
    getBankAccountData,
    postBankAccountData,
    getDataUsage,
    getConsentStatus,
    updateConsentStatus,
    getOffice365,
    postOffice365,
    getProfileConfig,
    postProfileConfig,
    getPublicProfile,
    getDegreePrograms,
    getWebsiteCertificates,
    getInvoices,
    postInvoice,
    getDunningLettersApi,
    getExmatriculationCertificates,
    transcriptOfRecords,
    postDuningLetterApi,
    getPaymentAgreementsApi,
    postPaymentAgreement,
    getAttendanceCertificates,
    semesterSpeaker,
    showTermsOfUse,
    aceptTermsOfUse,
    showUserAgreements,
    acceptUserAgreements,
    getRequiredDocuments,
    postRequiredDocument,
    deactivateOptin365,
    getDiplomaDocuments,
    getDiplomaDocument
  };
}
