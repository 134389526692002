import { Problem } from '@/models/error/problem';
import { HttpStatusCode } from 'axios';

export interface NotFoundProblem extends Problem {}

export class NotFoundProblem extends Problem {
  protected _detail: string;
  constructor(message: string, title: string, instance: string, detail: string, baseError: Error) {
    super(message, HttpStatusCode.NotFound, title, instance, baseError);
    this._detail = detail;
  }

  get detail(): string {
    return this._detail;
  }
}
