<template>
  <Menu ref="primeMenu" :model="profileMenu" :popup="true" class="profile-menu">
    <template #start>
      <template v-if="compactProfile">
        <div class="fom-overlay-menu__header">
          <div class="name">
            {{ `${compactProfile?.vorname} ${compactProfile?.nachname}` }}
          </div>
          <div class="profile-menu-infos">
            <span>{{ compactProfile?.institut }}</span>
            <span v-if="compactProfile.studiengangKuerzel !== ''"> | {{ compactProfile?.studiengangKuerzel }}</span>
            <span> | {{ compactProfile?.matrikelNummer }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col gap-2">
          <PrimeSkeleton class="!w-full !h-3" />
          <div class="flex gap-2">
            <PrimeSkeleton class="!w-10 !h-2" />
            <PrimeSkeleton class="!w-14 !h-2" />
            <PrimeSkeleton class="!w-8 !h-2" />
          </div>
        </div>
      </template>
    </template>
    <template #item="{ item }">
      <RouterLink v-if="item.route" :to="item.route" class="p-menuitem-link">
        <span class="p-menuitem-text">{{ item.label }}</span>
      </RouterLink>
    </template>
    <template #end>
      <div v-if="isAssl" class="p-menuitem" @click="endAssl()">
        <div class="p-menuitem-content">
          <div class="!py-2 !h-[41px] p-menuitem-link">
            <span class="text-danger-100">{{ $t('assl.end') }}</span>
          </div>
        </div>
      </div>

      <div class="p-menuitem" @click="onLogout">
        <div class="p-menuitem-content">
          <div class="!py-2 !h-[41px] p-menuitem-link">
            <div class="profile-menu-logout text-primary-800">
              <IconLogout />
              <span class="text-primary">{{ $t('logout') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Menu>
</template>

<script lang="ts" setup>
import type { MenuItem } from 'primevue/menuitem';

import IconLogout from '~icons/mdi/logout';
import { storeToRefs } from 'pinia';
import Menu from 'primevue/menu';
import PrimeSkeleton from 'primevue/skeleton';
import { computed, ref } from 'vue';

import { useCampusUserApi } from '@/composables/http/use-campus-user-api';
import useAuth from '@/composables/use-auth';
import AsslEndDialog from '@/components/dialogs/AsslEndDialog.vue';
import { useMiscStore } from '@/stores/misc';
import { useSitemapStore } from '@/stores/sitemap';
import { usePersonStore } from '@/stores/person';
import {useToast} from "primevue/usetoast";
import {useCampusSupport} from "@/composables/http/use-campus-support";
import {useI18n} from "vue-i18n";

// Stores
const { userManager } = useAuth();
const { postLogout } = useCampusUserApi();
const sitemapStore = useSitemapStore();
const toast = useToast();
const { t } = useI18n();
const { completeSupportRequest } = useCampusSupport();

// States
const { isAssl } = storeToRefs(useMiscStore());
const { compactProfile } = storeToRefs(usePersonStore());
const primeMenu = ref<InstanceType<typeof Menu>>();
const profileMenu = computed<MenuItem[]>(() => {
  let result: MenuItem[] = [];
  result.push({ separator: true });
  sitemapStore.sitemap
    .filter((entry: any) => entry.profil)
    .forEach((entry: any) => {
      result.push({
        label: entry.i18n,
        route: `/node/${entry.includeid ?? entry.nodeid}`
      });
    });
  result.push({ separator: true });
  return result;
});

const endAssl = async () => {
  try {
    if ((import.meta.env?.STORYBOOK || 'false') == 'true') {
      await new Promise((resolve) => setTimeout(resolve.bind(null, null), 3500));
    } else {
      await completeSupportRequest();
      isAssl.value = false;
      toast.add({
        detail: t('assl.dialog.end.detail'),
        summary: t('assl.dialog.end.summary'),
        severity: 'success',
        life: import.meta.env.VITE_TOAST_FIXED_LIFETIME ? import.meta.env.VITE_TOAST_FIXED_LIFETIME_VALUE : undefined
      });
      location.reload();
    }
  } catch (err) {
    console.error(err);
  }
}

/**
 * Logout from ASSL handler
 */
async function onLogout() {
  try {
    await postLogout();
  } catch (err) {
    console.error(err);
  } finally {
    userManager.value.signoutRedirect();
  }
}

// Expose Component
defineExpose({
  primeMenu
});
</script>
