<template>
  <div class="categories">
    <div v-for="category of searchCategories" :key="category.type">
      <label :for="category.type" class="item-label">
        <input type="checkbox" :id="category.type" v-model="categoryValue" :value="category.type" class="item-input" />
        <div class="item-content">
          <div class="icon">
            <IconPlus width="16" height="16" />
          </div>
          <div class="icon">
            <IconCheck width="16" height="16" />
          </div>
          <div class="icon">
            <IconMinus width="16" height="16" />
          </div>
          <span class="title">
            {{
              //@ts-ignore
              category.name ?? t(`search.category.${category.type}`)
            }}
          </span>
        </div>
      </label>
    </div>
  </div>
  <SearchFilter :has-search-result="props.hasSearchResult" />
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { useField } from 'vee-validate';

import IconPlus from '~icons/mdi/plus';
import IconMinus from '~icons/mdi/minus';
import IconCheck from '~icons/mdi/check';
import SearchFilter from '@/components/sidebars/search-sidebar/SearchFilter.vue';

import type { SearchTypeDto } from '@/types/contracts/generated/models/searchTypeDto';

const { t } = useI18n();

const { searchCategories } = storeToRefs(useSearchStore());

const { value: categoryValue } = useField<string[]>('category', undefined, {
  initialValue: []
});

watch(
  searchCategories,
  (newValue) => {
    categoryValue.value.push(
      ...(newValue as SearchTypeDto[])
        .filter((c) => c.selected)
        .map((c) => c.type as string)
        .filter((c) => !categoryValue.value.includes(c))
    );
  },
  { immediate: true }
);
const props = defineProps<{
  hasSearchResult?: Boolean;
}>();
</script>

<style scoped>
.content {
  @apply sticky top-0 left-0 z-10;
}

.categories {
  @apply flex flex-row flex-wrap gap-2 sm:gap-3 lg:gap-2 pb-4 lg:pb-8 px-4 lg:px-8 !bg-zinc-50 border-0 border-b border-solid border-zinc-200;

  .item-label {
    @apply relative inline-block rounded-lg;
  }

  .item-content {
    @apply md:w-full h-full bg-zinc-50 border border-solid border-[#ced4da] rounded-lg select-none flex gap-2 items-center py-1 px-2;

    .icon {
      @apply hidden sm:flex w-5 h-5 p-[2px] justify-center items-center border border-solid border-zinc-300 rounded-full;
    }

    .title {
      @apply text-sm font-semibold;
    }
  }

  .item-input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;

    &:hover + .item-content {
      @apply border-zinc-300;
    }

    &:checked + .item-content {
      @apply bg-primary-500 border-primary-500/50 text-white;
    }

    &:checked + .item-content .icon {
      @apply border-white;
    }

    & + .item-content .icon:nth-child(2),
    & + .item-content .icon:nth-child(3) {
      @apply hidden;
    }

    & + .item-content .icon:nth-child(1) {
      @apply sm:flex;
    }

    &:checked + .item-content .icon:nth-child(3) {
      @apply hidden;
    }

    &:checked + .item-content .icon:nth-child(1) {
      @apply hidden;
    }

    &:checked + .item-content .icon:nth-child(2) {
      @apply sm:flex;
    }

    &:checked:hover + .item-content .icon:nth-child(3) {
      @apply sm:flex;
    }

    &:checked:hover + .item-content .icon:nth-child(2) {
      @apply hidden;
    }
  }
}
</style>
