<template>
  <div class="form-control">
    <div class="control-field" :class="{ 'p-float-label': label && props.floating }">
      <slot
        name="default"
        v-bind="{
          id: id,
          disabled: props.disabled,
          required: props.context?.meta.required || false,
          invalid: typeof props.context?.errorMessage.value == 'string'
        }"
      />
      <label class="field-label" v-if="label && props.label != false">
        <component :is="props.icon" v-if="props.icon" class="field-icon" />
        <span>{{ label }}</span>
      </label>
    </div>

    <div
      class="control-validation"
      v-if="props.context && props.context.errors.value.length > 0 && props.context.errorMessage.value"
    >
      {{ props.context.errorMessage.value }}
    </div>
  </div>
</template>

<script lang="ts">
import type { FieldContext } from 'vee-validate';
import type { Component } from 'vue';
import { markRaw } from 'vue';
import { isReactive } from 'vue';
import { isRef } from 'vue';

export interface FormControlPassedProps {
  /**
   * A unique field id, may already be generated by the parent FormControl component.
   */
  id: string;

  /**
   * The field disabled state.
   */
  disabled?: boolean;

  /**
   * The field required state.
   */
  required: boolean;

  /**
   * The field invalid state.
   */
  invalid: boolean;
}

export interface FormControlProps {
  /**
   * Field Context
   */
  context?: FieldContext<any>;

  /**
   * A custom ID for this form control element.
   */
  id?: string;

  /**
   * Floating field icon used for this form control.
   */
  icon?: Component;

  /**
   * Overwrite the context label, or pass false to disable the label completely.
   */
  label?: string | false | null;

  /**
   * Field disabled state.
   */
  disabled?: boolean;

  /**
   * Whether to use a floating label or not.
   */
  floating?: boolean;
}

export interface FormControlSlots {
  /**
   * Default Field Content
   */
  default(props: FormControlPassedProps): any;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'FormControl'
}
</script>

<script lang="ts" setup>
import { computed } from 'vue';

// Define Component
const props = withDefaults(defineProps<FormControlProps>(), {
  label: null,
  floating: true
});
const slots = defineSlots<FormControlSlots>();

// Create ID
const id = computed(() => {
  return props.id ? props.id : crypto.randomUUID().replace('-', '');
});

const label = computed<string | null>(() => {
  if (props.label && typeof props.label == 'string') {
    return props.label;
  } else if (props.label !== false && props.context && props.context.label) {
    return (isRef(props.context.label) ? props.context.label.value : props.context.label) as string;
  } else {
    return null;
  }
});
</script>

<style>
.form-control {
  @apply flex flex-col w-full;

  &:not(:last-child) {
    @apply mb-3;
  }
}

.control-label {
  @apply text-sm font-semibold mb-2 px-1;
  @apply text-zinc-800;
}

.control-field {
  @apply relative;

  & .field-label {
    @apply flex flex-row gap-1 items-center text-base font-semibold pt-px px-2;
    line-height: 1.1rem;

    & svg {
      @apply -mt-px;
    }

    & span {
      @apply -mt-0.5;
    }
  }

  &:focus-within .field-label {
    @apply text-sm;
  }
}

.control-validation {
  @apply text-sm;
  @apply text-danger-100;
}
</style>
