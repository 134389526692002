<template>
  <div class="aside">
    <h3 class="aside-title">{{ $t('calendar.title') }}</h3>

    <PrimeSelectButton
      class="aside-select"
      optionLabel="label"
      optionValue="value"
      :allowEmpty="false"
      :options="toggleOptions"
      v-model="toggleValue" />

    <Calendar v-if="calendar && toggleValue === 'calendarDates'" 
      :calendar="calendar" 
      @doAction="onDoAction" />
    <ExamDatesView v-if="calendar && toggleValue === 'examDates'" 
      :calendar="calendar" 
      @doAction="onDoAction" />
  </div>
</template>

<script lang="ts">
import type { ActionDataDto } from '@/types/contracts/generated/models/actionDataDto';
import { ActionDtoType } from '@/types/contracts/generated/models/actionDtoType';

// Default Export, used for IDE auto-importing only
export default {
  name: 'AppointmentsComponent'
}
</script>

<script setup lang="ts">
import PrimeSelectButton from 'primevue/selectbutton';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import Calendar from '@/components/appointments/partials/CalendarComponent.vue';
import ExamDatesView from '@/components/appointments/partials/ExamDates.vue';
import { useCalendarStore } from '@/stores/calendar';

// Stores
const { t } = useI18n();
const router = useRouter();
const { fetchCalendarData } = useCalendarStore();

// States
const { calendar } = storeToRefs(useCalendarStore());
const toggleOptions = ref<{ label: string, value: string }[]>([
  { label: t('calendar.calendar_dates'), value: 'calendarDates' },
  { label: t('calendar.exam_dates'), value: 'examDates' }
]);
const toggleValue = ref<string>(toggleOptions.value[0].value);

// Component mounted
onMounted(() => {
  fetchCalendarData();
});

/**
 * Do Action Event Listener
 */
function onDoAction(component: string, data?: ActionDataDto) {
  if (component === ActionDtoType.StudyBookExamSignup) {
    props.close();
    router.push({ name: 'StudyBookExamSignup', params: { pid: data?.id } });
  } else if (component === ActionDtoType.StudyBookExamSubmission) {
    props.close();
    router.push({ name: 'StudyBookExamSubmission', params: { peid: data?.id } });
  } else if (component === ActionDtoType.Link) {
    window.open(data?.link);
  }
};

const props = defineProps<{
  close: () => void;
}>();
</script>

<style scoped>
.aside {
  @apply flex flex-col h-full pt-4 w-full;

  &-select {
    @apply flex justify-between mb-xs;

    :deep(.p-button) {
      @apply grow;
    }

    @screen md {
      @apply px-md;
    }

    @screen lg {
      @apply px-xl;
    }
  }

  &-title {
    @apply mb-xs font-bold text-zinc-700 text-md;

    @screen md {
      @apply px-md text-lg;
    }

    @screen lg {
      @apply px-xl;
    }
  }
}
</style>
