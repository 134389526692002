<template>
  <div>
    <div
      v-if="props.options && props.options.displayLecture && profile.fachgebiet || (profile.kompetenzfelder && profile.kompetenzfelder.length > 0)"
      class="flex flex-row gap-6 mb-4">
      <IconSchoolOutline class="text-xl text-primary shrink-0 " />

      <div>
        <div v-if="profile.fachgebiet">
          <div class="mb-2 font-semibold">
            {{ t('public_profile.headers.field') }}
          </div>
          <ul class="m-0">
            <li>
              {{ profile.fachgebiet }}
            </li>
          </ul>
        </div>
        <div v-if="profile.kompetenzfelder && profile.kompetenzfelder.length > 0">
          <div class="mt-4 mb-2 font-semibold">
            {{ t('public_profile.headers.areas_of_expertise') }}
          </div>
          <ul class="m-0">
            <li v-for="(entry, index) in profile.kompetenzfelder" :key="index">
              {{ entry }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-6 mt-6 mb-4"
      v-if="profile.kommunikation && profile.kommunikation.filter(entry => entry.typ === Kommunikationsfeldtyp.EMail).length > 0">
      <IconEmail class="w-8 h-8 fill-primary shrink-0 " />
      <div>
        <div
          v-for="(entry, index) in profile.kommunikation.filter(element => element.typ === Kommunikationsfeldtyp.EMail)"
          :key="index" class="mb-2 last:mb-0">
          <div>
            <span class="font-semibold">{{ entry.name }}</span>
          </div>
          <a :href="`mailto:${entry.value}`" class="text-primary">{{ entry.value }}</a>
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-6 mt-6"
      v-if="profile.kommunikation && profile.kommunikation.filter(entry => entry.typ === Kommunikationsfeldtyp.Phone || entry.typ === Kommunikationsfeldtyp.Mobile).length > 0">
      <IconPhoneMessage class="w-8 h-8 fill-primary shrink-0" />
      <div>
        <div
          v-for="(entry, index) in profile.kommunikation.filter(element => element.typ === Kommunikationsfeldtyp.Phone)"
          :key="index" class="mb-2 last:mb-0">
          <div>
            <span class="font-semibold">{{ entry.name }}</span>
          </div>
          <a :href="`tel:${entry.value}`" class="text-primary">{{ entry.value }}</a>
        </div>
        <div
          v-for="(entry, index) in profile.kommunikation.filter(element => element.typ === Kommunikationsfeldtyp.Mobile)"
          :key="index" class="mb-2 last:mb-0">
          <div>
            <span class="font-semibold">{{ entry.name }}</span>
          </div>
          <a :href="`tel:${entry.value}`" class="text-primary">{{ entry.value }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'PublicProfileBase'
}
</script>

<script setup lang="ts">
import type { ProfilzusatzDto } from "@/types/contracts/generated/models/profilzusatzDto";
import { Kommunikationsfeldtyp } from "@/types/contracts/generated/models/kommunikationsfeldtyp";
import IconPhoneMessage from '@/components/icons/IconPhoneMessage.vue';
import IconEmail from '@/components/icons/IconEmail.vue';

import IconSchoolOutline from '~icons/mdi/school-outline';
import { useI18n } from "vue-i18n";

const props = defineProps<{
  profile: ProfilzusatzDto,
  options: {
    displayLecture: boolean,
  }
}>();

const { t } = useI18n();
</script>
