import type { Directive, DirectiveBinding } from 'vue';

const callbacks = new Map();

export type resizeCallbackFunction = (
  source: { width: number; height: number },
  client: { width: number; height: number }
) => void;

export default {
  /**
   * Component Mounted
   * @param el
   * @param binding
   * @returns
   */
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const resizeCallback = binding.value;
    if (typeof resizeCallback != 'function') {
      return;
    }

    // Set resize callback handler
    const resizeCallbackHandler = function (this: HTMLElement, cb: resizeCallbackFunction) {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      cb({ width: this.offsetWidth, height: this.offsetHeight }, { width, height });
    }.bind(el, resizeCallback);
    callbacks.set(el, resizeCallbackHandler);

    // Attach listener
    window.addEventListener('resize', resizeCallbackHandler, { passive: true });

    // Immediate Call
    if (typeof binding.modifiers.immediate === 'boolean' && binding.modifiers.immediate) {
      resizeCallbackHandler();
    }
  },

  /**
   * Component Mounted
   * @param el
   * @param binding
   * @returns
   */
  unmounted(el, binding) {
    if (!callbacks.has(el)) {
      return;
    }

    // Detach listener
    const resizeCallbackHandler = callbacks.get(el);
    window.removeEventListener('resize', resizeCallbackHandler);
    callbacks.delete(el);
  }
} as Directive;
