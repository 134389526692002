<template>
  <div class="w-60 md:w-80 opacity-40">
    <svg width="100%" viewBox="0 0 700 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M633.785 172.118H33.8913C30.2344 172.118 27.2725 175.992 27.2725 180.775V222.897C27.2725 227.68 30.2344 231.554 33.8913 231.554H633.785C637.442 231.554 640.404 227.68 640.404 222.897V180.775C640.404 175.992 637.442 172.118 633.785 172.118Z"
        fill="#E4E4E4"
      />
      <path
        d="M24.7901 163.861H623.695C623.937 163.861 624.484 163.984 625.118 164.842C625.75 165.698 626.263 167.076 626.263 168.776V212.35C626.263 214.051 625.75 215.428 625.118 216.286C624.484 217.143 623.937 217.266 623.695 217.266H24.7901C24.5493 217.266 24.0011 217.143 23.3683 216.286C22.7362 215.428 22.2227 214.051 22.2227 212.35V168.776C22.2227 167.076 22.7362 165.698 23.3683 164.842C24.0011 163.984 24.5493 163.861 24.7901 163.861Z"
        fill="#F7F7F7"
        stroke="#BCBCBC"
        stroke-width="8"
      />
      <g style="mix-blend-mode: darken">
        <path
          d="M529.698 286.592C569.019 286.592 600.895 254.252 600.895 214.361C600.895 174.469 569.019 142.13 529.698 142.13C490.377 142.13 458.501 174.469 458.501 214.361C458.501 254.252 490.377 286.592 529.698 286.592Z"
          fill="#E4E4E4"
        />
      </g>
      <mask
        id="mask0_220_1766"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="451"
        y="130"
        width="131"
        height="133"
      >
        <path
          d="M471.568 242.013C479.364 249.922 488.943 255.787 499.475 259.098C510.007 262.408 521.175 263.069 532.014 261.018C542.852 258.971 553.038 254.272 561.687 247.334C570.335 240.396 577.189 231.425 581.653 221.196C569.12 226.826 555.202 228.476 541.725 225.927C528.251 223.379 515.853 216.751 506.161 206.919C496.47 197.086 489.937 184.508 487.425 170.838C484.913 157.167 486.539 143.046 492.089 130.331C482.006 134.86 473.16 141.811 466.322 150.585C459.481 159.359 454.849 169.69 452.831 180.689C450.81 191.685 451.458 203.015 454.724 213.7C457.988 224.385 463.771 234.103 471.568 242.013Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_220_1766)">
        <path d="M460.475 238.363H453.355V245.585H460.475V238.363Z" fill="#0C5551" />
        <path d="M460.475 213.086H453.355V220.308H460.475V213.086Z" fill="#0C5551" />
        <path d="M460.475 187.81H453.355V195.032H460.475V187.81Z" fill="#0C5551" />
        <path d="M460.475 162.536H453.355V169.758H460.475V162.536Z" fill="#0C5551" />
        <path d="M460.474 137.26H453.354V144.482H460.474V137.26Z" fill="#0C5551" />
        <path d="M485.386 238.363H478.267V245.585H485.386V238.363Z" fill="#0C5551" />
        <path d="M485.386 213.086H478.267V220.308H485.386V213.086Z" fill="#0C5551" />
        <path d="M485.386 187.81H478.267V195.032H485.386V187.81Z" fill="#0C5551" />
        <path d="M485.386 162.536H478.267V169.758H485.386V162.536Z" fill="#0C5551" />
        <path d="M485.386 137.26H478.267V144.483H485.386V137.26Z" fill="#0C5551" />
        <path d="M510.3 238.363H503.181V245.585H510.3V238.363Z" fill="#0C5551" />
        <path d="M510.3 213.086H503.181V220.308H510.3V213.086Z" fill="#0C5551" />
        <path d="M510.3 187.81H503.181V195.032H510.3V187.81Z" fill="#0C5551" />
        <path d="M510.3 162.536H503.181V169.758H510.3V162.536Z" fill="#0C5551" />
        <path d="M510.3 137.26H503.181V144.483H510.3V137.26Z" fill="#0C5551" />
        <path d="M535.215 238.363H528.096V245.585H535.215V238.363Z" fill="#0C5551" />
        <path d="M535.215 213.086H528.096V220.308H535.215V213.086Z" fill="#0C5551" />
        <path d="M535.215 187.81H528.096V195.032H535.215V187.81Z" fill="#0C5551" />
        <path d="M535.215 162.536H528.096V169.758H535.215V162.536Z" fill="#0C5551" />
        <path d="M535.215 137.26H528.096V144.483H535.215V137.26Z" fill="#0C5551" />
        <path d="M560.125 238.363H553.007V245.585H560.125V238.363Z" fill="#0C5551" />
        <path d="M560.125 213.086H553.007V220.308H560.125V213.086Z" fill="#0C5551" />
        <path d="M560.125 187.81H553.007V195.032H560.125V187.81Z" fill="#0C5551" />
        <path d="M560.125 162.536H553.007V169.758H560.125V162.536Z" fill="#0C5551" />
        <path d="M560.125 137.26H553.007V144.483H560.125V137.26Z" fill="#0C5551" />
        <path d="M585.04 238.363H577.922V245.585H585.04V238.363Z" fill="#0C5551" />
        <path d="M585.04 213.086H577.922V220.308H585.04V213.086Z" fill="#0C5551" />
        <path d="M585.04 187.81H577.922V195.032H585.04V187.81Z" fill="#0C5551" />
        <path d="M585.04 162.536H577.922V169.758H585.04V162.536Z" fill="#0C5551" />
        <path d="M585.04 137.26H577.922V144.483H585.04V137.26Z" fill="#0C5551" />
      </g>
      <path
        d="M596.543 274.595C595.691 274.595 594.848 274.434 594.059 274.12C593.272 273.808 592.559 273.345 591.957 272.766L565.516 247.32C564.309 246.146 563.632 244.563 563.637 242.908C563.64 241.258 564.325 239.676 565.537 238.507C566.752 237.34 568.394 236.683 570.113 236.678C571.829 236.676 573.474 237.325 574.693 238.489L601.132 263.935C602.038 264.809 602.655 265.921 602.907 267.132C603.156 268.344 603.027 269.598 602.536 270.74C602.046 271.882 601.212 272.856 600.146 273.542C599.079 274.227 597.825 274.595 596.543 274.595Z"
        fill="#BCBCBC"
      />
      <path
        d="M594.522 263.747L590.236 268.2C585.747 272.863 585.747 280.424 590.236 285.088L646.797 343.855C651.289 348.519 658.566 348.519 663.055 343.855L667.341 339.403C671.83 334.736 671.83 327.176 667.341 322.512L610.779 263.747C606.29 259.082 599.011 259.082 594.522 263.747Z"
        fill="#F6F6F6"
      />
      <path
        d="M656.157 352.477C653.905 352.484 651.671 352.044 649.59 351.189C647.509 350.332 645.617 349.076 644.029 347.488L584.821 288.656C583.228 287.074 581.966 285.197 581.104 283.129C580.242 281.061 579.798 278.846 579.798 276.608C579.798 274.371 580.242 272.155 581.104 270.088C581.966 268.02 583.228 266.143 584.821 264.56L589.303 260.105C592.521 256.915 596.883 255.124 601.428 255.124C605.977 255.124 610.337 256.915 613.556 260.105L672.767 318.947C675.976 322.145 677.778 326.479 677.778 330.995C677.778 335.511 675.976 339.844 672.767 343.042L668.278 347.501C666.688 349.086 664.798 350.34 662.717 351.194C660.639 352.047 658.407 352.484 656.157 352.477ZM601.431 265.28C600.521 265.277 599.621 265.454 598.78 265.8C597.941 266.145 597.177 266.651 596.535 267.291L592.053 271.746C591.409 272.384 590.901 273.143 590.553 273.977C590.203 274.812 590.024 275.707 590.024 276.608C590.024 277.512 590.203 278.407 590.553 279.242C590.901 280.076 591.409 280.835 592.053 281.473L651.265 340.302C652.581 341.554 654.334 342.252 656.157 342.252C657.981 342.252 659.734 341.554 661.05 340.302L665.539 335.846C666.833 334.553 667.559 332.806 667.559 330.982C667.559 329.16 666.833 327.413 665.539 326.12L606.326 267.289C605.683 266.651 604.92 266.145 604.08 265.8C603.241 265.454 602.338 265.277 601.431 265.28Z"
        fill="#BCBCBC"
      />
      <path
        d="M519.892 261.273C567.258 261.273 582.05 238.207 586.869 193.057C586.869 144.541 564.106 120.879 516.738 120.879C475.782 127.259 450.505 141.596 450.505 190.113C455.805 239.68 472.521 261.273 519.892 261.273Z"
        fill="#F6F6F6"
      />
      <path
        d="M518.669 266.396C506.461 266.396 494.443 263.339 483.678 257.5C472.912 251.661 463.732 243.217 456.951 232.916C450.17 222.617 445.998 210.779 444.801 198.452C443.606 186.126 445.424 173.69 450.097 162.245C454.77 150.803 462.153 140.705 471.588 132.848C481.026 124.991 492.224 119.617 504.199 117.201C516.172 114.787 528.544 115.404 540.226 119C551.908 122.598 562.533 129.062 571.166 137.821C581.556 148.351 588.632 161.775 591.499 176.389C594.369 191.002 592.899 206.151 587.277 219.919C581.658 233.684 572.137 245.448 559.924 253.722C547.711 261.995 533.353 266.406 518.669 266.396ZM518.669 125.954C503.816 125.965 489.427 131.199 477.954 140.769C466.48 150.336 458.629 163.645 455.741 178.426C452.851 193.21 455.105 208.55 462.112 221.837C469.121 235.124 480.455 245.534 494.178 251.293C507.903 257.053 523.171 257.805 537.38 253.422C551.59 249.038 563.864 239.792 572.107 227.258C580.354 214.722 584.06 199.675 582.594 184.68C581.132 169.683 574.591 155.664 564.084 145.012C558.123 138.957 551.042 134.157 543.249 130.887C535.457 127.616 527.102 125.939 518.669 125.954Z"
        fill="#BCBCBC"
      />
      <mask
        id="mask1_220_1766"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="451"
        y="123"
        width="153"
        height="140"
      >
        <path
          d="M475.026 240.839C484.144 249.143 495.348 255.301 507.667 258.777C519.985 262.252 533.046 262.946 545.723 260.793C558.401 258.643 570.314 253.71 580.429 246.426C590.544 239.142 598.561 229.724 603.782 218.985C589.123 224.896 572.844 226.627 557.083 223.951C541.323 221.276 526.823 214.318 515.487 203.995C504.15 193.672 496.511 180.466 493.574 166.115C490.635 151.761 492.536 136.936 499.027 123.586C487.234 128.341 476.89 135.639 468.891 144.85C460.89 154.062 455.473 164.909 453.112 176.456C450.748 188.001 451.507 199.897 455.326 211.114C459.143 222.332 465.907 232.535 475.026 240.839Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_220_1766)">
        <path d="M462.049 237.007H453.724V244.59H462.049V237.007Z" fill="#BCBCBC" />
        <path d="M462.049 210.469H453.724V218.052H462.049V210.469Z" fill="#BCBCBC" />
        <path d="M462.049 183.933H453.724V191.515H462.049V183.933Z" fill="#BCBCBC" />
        <path d="M462.049 157.399H453.724V164.982H462.049V157.399Z" fill="#BCBCBC" />
        <path d="M462.049 130.862H453.724V138.444H462.049V130.862Z" fill="#BCBCBC" />
        <path d="M491.187 237.007H482.86V244.59H491.187V237.007Z" fill="#BCBCBC" />
        <path d="M491.187 210.469H482.86V218.052H491.187V210.469Z" fill="#BCBCBC" />
        <path d="M491.187 183.933H482.86V191.515H491.187V183.933Z" fill="#BCBCBC" />
        <path d="M491.187 157.399H482.86V164.982H491.187V157.399Z" fill="#BCBCBC" />
        <path d="M491.186 130.862H482.859V138.444H491.186V130.862Z" fill="#BCBCBC" />
        <path d="M520.325 237.007H511.999V244.59H520.325V237.007Z" fill="#BCBCBC" />
        <path d="M520.325 210.469H511.999V218.052H520.325V210.469Z" fill="#BCBCBC" />
        <path d="M520.325 183.933H511.999V191.515H520.325V183.933Z" fill="#BCBCBC" />
        <path d="M520.325 157.399H511.999V164.982H520.325V157.399Z" fill="#BCBCBC" />
        <path d="M520.325 130.862H511.999V138.444H520.325V130.862Z" fill="#BCBCBC" />
        <path d="M549.464 237.007H541.139V244.59H549.464V237.007Z" fill="#BCBCBC" />
        <path d="M549.464 210.469H541.139V218.052H549.464V210.469Z" fill="#BCBCBC" />
        <path d="M549.464 183.933H541.139V191.515H549.464V183.933Z" fill="#BCBCBC" />
        <path d="M549.464 157.399H541.139V164.982H549.464V157.399Z" fill="#BCBCBC" />
        <path d="M549.464 130.862H541.139V138.444H549.464V130.862Z" fill="#BCBCBC" />
        <path d="M578.603 237.007H570.276V244.59H578.603V237.007Z" fill="#BCBCBC" />
        <path d="M578.603 210.469H570.276V218.052H578.603V210.469Z" fill="#BCBCBC" />
        <path d="M578.603 183.933H570.276V191.515H578.603V183.933Z" fill="#BCBCBC" />
        <path d="M578.603 157.399H570.276V164.982H578.603V157.399Z" fill="#BCBCBC" />
        <path d="M578.603 130.862H570.276V138.444H578.603V130.862Z" fill="#BCBCBC" />
        <path d="M607.742 237.007H599.416V244.59H607.742V237.007Z" fill="#BCBCBC" />
        <path d="M607.742 210.469H599.416V218.052H607.742V210.469Z" fill="#BCBCBC" />
        <path d="M607.742 183.933H599.416V191.515H607.742V183.933Z" fill="#BCBCBC" />
        <path d="M607.742 157.399H599.416V164.982H607.742V157.399Z" fill="#BCBCBC" />
        <path d="M607.742 130.862H599.416V138.444H607.742V130.862Z" fill="#BCBCBC" />
      </g>
      <path
        opacity="0.5"
        d="M338.296 300.214H55.644C53.9209 300.214 52.5254 302.217 52.5254 304.692V326.479C52.5254 328.952 53.9209 330.956 55.644 330.956H338.296C340.018 330.956 341.414 328.952 341.414 326.479V304.692C341.414 302.217 340.018 300.214 338.296 300.214Z"
        fill="#E4E4E4"
      />
      <path
        d="M389.49 255.124H56.2027C54.171 255.124 52.5254 257.127 52.5254 259.602V281.389C52.5254 283.862 54.171 285.866 56.2027 285.866H389.49C391.521 285.866 393.167 283.862 393.167 281.389V259.602C393.167 257.127 391.521 255.124 389.49 255.124Z"
        fill="#E4E4E4"
      />
      <path
        opacity="0.3"
        d="M302.324 353.502H55.2513C53.7453 353.502 52.5254 355.505 52.5254 357.98V379.767C52.5254 382.24 53.7453 384.244 55.2513 384.244H302.324C303.83 384.244 305.051 382.24 305.051 379.767V357.98C305.051 355.505 303.83 353.502 302.324 353.502Z"
        fill="#E4E4E4"
      />
      <path d="M432.323 368.874L416.161 383.22V354.526L432.323 368.874Z" fill="#BCBCBC" />
      <path d="M475.757 368.874L459.596 383.22V354.526L475.757 368.874Z" fill="#BCBCBC" />
      <path d="M518.182 368.874L502.021 383.22V354.526L518.182 368.874Z" fill="#BCBCBC" />
      <path d="M561.616 368.874L545.454 383.22V354.526L561.616 368.874Z" fill="#BCBCBC" />
      <path d="M475.757 315.585L459.596 329.932V301.238L475.757 315.585Z" fill="#BCBCBC" />
      <path d="M518.182 315.585L502.021 329.932V301.238L518.182 315.585Z" fill="#BCBCBC" />
      <path d="M561.616 315.585L545.454 329.932V301.238L561.616 315.585Z" fill="#BCBCBC" />
      <path
        d="M23.019 390.522C23.0632 394.954 26.5776 398.509 30.8681 398.462C35.1575 398.415 38.5985 394.784 38.5531 390.353C38.5077 385.922 34.9934 382.367 30.704 382.413C26.4147 382.46 22.9736 386.091 23.019 390.522Z"
        fill="#F6F6F6"
      />
      <path
        d="M22.2231 312.692C22.2685 317.124 25.7828 320.679 30.0722 320.632C34.3627 320.585 37.8037 316.955 37.7583 312.523C37.713 308.091 34.1986 304.536 29.9093 304.583C25.6199 304.629 22.1789 308.26 22.2231 312.692Z"
        fill="#F6F6F6"
      />
      <path
        d="M94.8481 389.74C94.8923 394.173 98.4078 397.728 102.697 397.681C106.987 397.633 110.428 394.003 110.382 389.571C110.337 385.14 106.822 381.585 102.533 381.632C98.2438 381.678 94.8027 385.309 94.8481 389.74Z"
        fill="#F6F6F6"
      />
      <path
        d="M94.0542 311.91C94.0984 316.342 97.6139 319.896 101.903 319.85C106.193 319.803 109.634 316.173 109.588 311.741C109.543 307.309 106.029 303.754 101.739 303.801C97.4498 303.847 94.0088 307.478 94.0542 311.91Z"
        fill="#F6F6F6"
      />
    </svg>
  </div>
</template>
