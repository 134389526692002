import { defineAsyncComponent, reactive, type Plugin } from 'vue';

export default {
  /**
   * Install Vue Plugin
   * @param app
   */
  install(app) {
    app.component(
      'IconLearn',
      defineAsyncComponent(() => import(`./../components/icons/IconLearn.vue`))
    );
    app.component(
      'IconTag',
      defineAsyncComponent(() => import(`./../components/icons/IconTag.vue`))
    );
    app.component(
      'IconCertificate',
      defineAsyncComponent(() => import(`./../components/icons/IconCertificate.vue`))
    );
    app.component(
      'IconNewspaper',
      defineAsyncComponent(() => import(`./../components/icons/IconNewspaper.vue`))
    );
    app.component(
      'IconBookshelf',
      defineAsyncComponent(() => import(`./../components/icons/IconBookshelf.vue`))
    );
    app.component(
      'IconHelp',
      defineAsyncComponent(() => import(`./../components/icons/IconHelp.vue`))
    );

    const indicator = reactive<{ width: string; transform: string }>({
      width: '20px',
      transform: ' translateX(0)'
    });
    app.provide('menu-indicator', indicator);
  }
} as Plugin;
