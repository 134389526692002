const RouteTable: { [key: string]: string } = {
  'activity/ActivityTablePage': 'activities/ActivityTablePage',
  'application/ApplicationOverview': 'application/ApplicationOverviewPage',
  'cms/CmsView': 'cms/CmsPage',
  'certificates-and-requests/CertificatesPage': 'certs/CertificatesPage',
  'certificates-and-requests/AttendanceCertificatesPage': 'certs/AttendanceCertificatesPage',
  'certificates-and-requests/EnrollmentCertificatesPage': 'certs/EnrollmentCertificatesPage',
  'certificates-and-requests/ExmatriculationCertificatesPage': 'certs/ExmatriculationCertificatesPage',
  'certificates-and-requests/PerformanceProofPage': 'certs/PerformanceProofPage',
  'course-of-studies/CourseOfStudiesPage': 'studies/CourseOfStudiesPage',
  'course-of-studies/CoursePage': 'studies/CoursePage',
  'course-of-studies/CourseOfStudy': 'studies/CourseOfStudyPage',
  'course-of-studies/CourseOfStudiesContainer': 'studies/CourseOfStudiesContainerPage',
  'course-signup/CourseSignupPage': 'studies/CourseSignupPage',
  'study-book/StudyBookTable': 'studybook/StudyBookTablePage',
  'study-book/StudyBookContainer': 'studybook/StudyBookContainerPage',
  'curriculum-and-exams/CompetenceProfilePage': 'exams/CompetenceProfilePage',
  'curriculum-and-exams/ExamRegulationsAndAnnexesPage': 'exams/ExamRegulationsAndAnnexesPage',
  'curriculum-and-exams/ThreehundredsixtyDegreeFeedbackPage': 'exams/Feedback360Page',
  'dashboard/DashboardPage': 'dashboard/DashboardPage',
  'help-and-contact/ContactPage': 'help/ContactPage',
  'help-and-contact/GuidedToursPage': 'help/GuidedToursPage',
  'help-and-contact/ImpressumPage': 'help/ImprintPage',
  Include: 'IncludePage',
  'invoices/DunningLetters': 'invoices/DunningLettersPage',
  'invoices/InvoicePage': 'invoices/InvoicePage',
  'invoices/PaymentAgreements': 'invoices/PaymentAgreementsPage',
  'literary-research/BeckOnlinePage': 'research/subpages/BeckOnlinePage',
  'literary-research/DupPublisherPage': 'research/subpages/DupPublisherPage',
  'literary-research/EbscoPage': 'research/subpages/EbscoPage',
  'literary-research/ShakerPublisherPage': 'research/subpages/ShakerPublisherPage',
  'literary-research/StartupScenePage': 'research/subpages/StartupScenePage',
  'literary-research/OverarchingResearchToolsPage': 'research/OverarchingResearchToolsPage',
  'literary-research/OverarchingResearchToolsContainer': 'research/OverarchingResearchToolsContainerPage',
  'literary-research/SpecificDatabasesPage': 'research/SpecificDatabasesPage',
  'literary-research/SpecificDatabasesContainer': 'research/SpecificDatabasesContainerPage',
  'profile/BankAccountDataPage': 'profile/IndexPage',
  'profile/ContactDataPage': 'profile/IndexPage',
  'profile/CourseInfoPage': 'profile/IndexPage',
  'profile/SeminarInfoPage': 'profile/IndexPage',
  'profile/DataUsagePage': 'profile/IndexPage',
  'profile/IDCardPage': 'profile/IndexPage',
  'profile/Office365Page': 'profile/IndexPage',
  'profile/PasswordPage': 'profile/IndexPage',
  'profile/PersonalInformationPage': 'profile/IndexPage',
  'profile/PublicProfilePage': 'profile/IndexPage',
  'profile/SubmittedDocumentsPage': 'profile/IndexPage',

  // @todo missing routes due to db
  'assl/AsslLogin': 'assl/LoginPage',
  'docent-search/DocentSearchPage': 'docent/DocentSearchPage'
};

// Export Module
export default RouteTable;
export { RouteTable };
