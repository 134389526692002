
import { defineStore } from 'pinia';
import { useDialog } from 'primevue/usedialog';


export interface DownloadStoreStates {
  url: Promise<string> | string | null;
  error: string | null;
  visible: boolean;
  strings: {
    title: string;
    action: string;
    titlePrepared: string;
    loading: string;
  }
};

export const useDownloadsStore = defineStore('downloads', {

  /**
   * Initial States
   */
  state: (): DownloadStoreStates => ({
    url: null,
    error: null,
    visible: false,
    strings: {
      title: 'downloads.title',
      action: 'downloads.label',
      titlePrepared: 'downloads.titlePrepared',
      loading: 'downloads.loading',
    }
  }),

  /**
   * Getters
   */
  getters: {

  },

  /**
   * Actions
   */
  actions: {
    /**
     * Request Dialog
     * @param url 
     */
    requestDialog(url: Promise<string> | string, title?: null|string, action?: null|string, titlePrepared?: null|string, loading?: null|string) {
      this.url = url;
      this.visible = true;
      this.strings.title = title || 'downloads.title';
      this.strings.action = action || 'downloads.label';
      this.strings.titlePrepared = titlePrepared || 'downloads.titlePrepared';
      this.strings.loading = loading || 'downloads.loading';

      if (url instanceof Promise) {
        url.then(
          (value) => this.url = value, 
          (err) => {
            this.error = err;
            this.url = null;
          }
        )
      }
    }
  }

});
