import type { AxiosResponse } from 'axios';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}`;

export function useCampusMiscApi() {
  const { makeGetRequest } = useResource();

  const getDownloadToken = (): Promise<AxiosResponse> => makeGetRequest(`${baseUrl}/downloadtoken`);

  return {
    getDownloadToken
  };
}
