<template>
  <Sidebar v-model:visible="sidebarOpen" position="right" class="h-screen sidebar-large sidebar-search">
    <template #header>
      <h1 class="!mb-0">{{ $t('search.input.label') }}</h1>
    </template>

    <div class="content">
      <SearchInput />
      <SearchCategories :has-search-result="hasSearchResult" />
    </div>

    <SearchResults :has-search-result="hasSearchResult" />
  </Sidebar>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'SearchSidebar'
}
</script>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { useForm } from 'vee-validate';
import { tryOnUnmounted, watchDebounced } from '@vueuse/core';
import Sidebar from 'primevue/sidebar';
import SearchResults from '@/components/sidebars/search-sidebar/SearchResults.vue';
import SearchCategories from '@/components/sidebars/search-sidebar/SearchCategories.vue';
import SearchInput from '@/components/sidebars/search-sidebar/SearchInput.vue';

import { SearchSort } from '@/types/contracts/generated/models/searchSort';
import type { LuceneType } from '@/types/contracts/generated/models/luceneType';

const { searchResult, page, doc } = storeToRefs(useSearchStore());
const { getSearchCategories, handleSearch, $reset } = useSearchStore();

getSearchCategories();
const loading = ref(false);
const router = useRouter();

tryOnUnmounted(() => {
  $reset();
});

const hasSearchResult = ref(searchResult != null);

watch(router.currentRoute, () => {
  close();
});

const { values } = useForm({
  initialValues: {
    sort: SearchSort.Relevance,
    category: [],
    search: undefined as unknown as string,
    from: undefined as unknown as Date,
    to: undefined as unknown as Date
  }
});

watchDebounced(
  values,
  (newValue) => {
    page.value = 0;
    doc.value = undefined;

    if (newValue.search === '') {
      searchResult.value = void 0;
    }

    if (newValue.search && newValue.category?.length > 0) {
      searchResult.value = void 0;
      loading.value = true;
      handleSearch({
        query: newValue.search,
        from: newValue.from?.toISOString() ?? undefined,
        to: newValue.to?.toISOString() ?? undefined,
        types: newValue.category.map((c) => ({ type: c as LuceneType, selected: true })),
        sortby: newValue.sort
      }).finally(() => (loading.value = false));
    }
  },
  {
    debounce: 300,
    maxWait: 1000,
    immediate: true
  }
);

const sidebarOpen = ref<boolean>(false);

function open() {
  sidebarOpen.value = true;
}

function close() {
  sidebarOpen.value = false;
}

function toggle() {
  sidebarOpen.value = !sidebarOpen.value;
}

defineExpose({
  isOpen: sidebarOpen,
  open,
  close,
  toggle
});
</script>

<style scoped>
.content {
  @apply max-w-4xl;
}

.search-results {
  @apply h-full !p-0 flex flex-col max-w-4xl relative;
}

.results-wrapper {
  @apply flex-1 !pt-0 overflow-y-auto;
}
</style>
