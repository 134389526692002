<template>
  <article class="event">
    <div class="event-header">
      <div class="event-header-container">
        <div class="event-icon-group">
          <component :is="IconType.get(entry.type) || Calendar" class="event-icon" />
          <span v-if="entry.type" class="event-type">
            {{ entry.subtitle ? entry.subtitle : entry.type }}
          </span>
        </div>
        <span v-if="entry.von">·</span>
        <TimeStamp :from="entry.von" :to="entry.bis" />
      </div>
    </div>

    <div class="event-body">
      <div class="event-title" :title="entry.title">{{ entry.title }}</div>

      <CalendarHint v-if="entry.highlight" :hint="entry.highlight" />

      <RoomComponent v-if="entry.raum && entry.raum.raum !== 'Webinar'" :room="entry.raum" :toggle="true" />

      <!-- Person -->
      <div v-for="(person, index) in entry.personen" :key="index" class="cursor-pointer">
          <PersonComponent
            v-if="person.id"
            :person-id="person.id"
            :dialog-options="{ displayLecture: true }"
            nameSize="16"
            class="text-[16px]"
            doesDialog
          />
        <div v-else>
          {{ `${person.titel} ${person.name}` }}
        </div>
      </div>

      <ButtonStd
        v-for="(action, index) in entry.actions"
        :key="index"
        size="small"
        :action="action"
        :label="action.text"
        @click="emit('doAction', action.component ?? '', action.data)"
      />
    </div>
  </article>
</template>

<script lang="ts">
import { EventType } from '@/types/contracts/generated/models/eventType';
import type { EventDto3 } from '@/types/contracts/generated/models/eventDto3';
import type { ActionDataDto } from '@/types/contracts/generated/models/actionDataDto';
import type { FunctionalComponent } from 'vue';
</script>

<script setup lang="ts">
import HumanMaleBoard from '~icons/mdi/human-male-board';
import CastEducation from '~icons/mdi/cast-education';
// import BookEdit from '~icons/mdi/book-edit';
import Calendar from '~icons/mdi/calendar';
import FileEdit from '~icons/mdi/file-edit';

import TimeStamp from '@/components/appointments/partials/TimeStamp.vue';
import CalendarHint from '@/components/appointments/partials/CalendarHint.vue';
import ButtonStd from '@/components/buttons/ButtonStd.vue';
import RoomComponent from '@/components/shared/RoomComponent.vue';
import PersonComponent from '@/components/shared/PersonComponent.vue';

defineProps<{
  entry: EventDto3;
}>();

const emit = defineEmits<{
  doAction: [comp: string, data?: ActionDataDto];
}>();

const IconType = new Map<string | undefined, FunctionalComponent>([
  [EventType.Vorlesung, HumanMaleBoard],
  [EventType.Webinar, CastEducation],
  [EventType.Pruefung, FileEdit],
]);
</script>

<style scoped>
.event {
  @apply bg-white px-sm py-xs border border-solid border-zinc-100 rounded-md pr-sm;

  @screen md {
    @apply pr-md;
  }

  @screen lg {
    @apply pr-xl;
  }

  &-header {
    @apply flex items-center gap-2;
  }

  &-header-container {
    @apply flex flex-row items-center gap-2;
  }

  &-icon-group {
    @apply flex gap-1;
  }

  &-icon {
    @apply flex-shrink-0 w-4 h-4 text-zinc-400 fill-current;
  }

  &-type {
    @apply text-sm font-semibold text-zinc-500;
  }

  &-title {
    @apply my-1 mb-2 text-black md:mb-0 truncate;
    @apply text-[16px] max-w-[40ch];
  }

  &-body {
    @apply space-y-1;
  }
}
</style>
