const RouteStatics: { [key: string]: { name: string; parent: string; component: string } } = {
  '/node/6178/study-extension': {
    name: '6178-study-extension',
    parent: '6178',
    component: 'application/subpages/StudyExtension'
  },
  '/node/6178/study-place-change': {
    name: '6178-study-place-change',
    parent: '6178',
    component: 'application/subpages/StudyPlaceChange'
  },
  '/node/6178/study-type-change': {
    name: '6178-study-type-change',
    parent: '6178',
    component: 'application/subpages/StudyTypeChange'
  },
  '/node/6178/vacation-semester': {
    name: '6178-vacation-semester',
    parent: '6178',
    component: 'application/subpages/VacationSemester'
  }
};

// Export Module
export default RouteStatics;
export { RouteStatics };
