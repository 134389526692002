import type { Plugin } from 'vue';

/**
 * Append legacy filters,
 * @todo Check if those are still required.
 */
export default {
  /**
   * Install Vue Plugin
   * @param app
   */
  install(app) {
    app.config.globalProperties.$filters = {
      price(input: number) {
        return isNaN(input) ? '-' : '$' + input.toFixed(2);
      },

      formatDate(value: string) {
        return value
          ? new Intl.DateTimeFormat('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(new Date(value))
          : void 0;
      },

      formatHour(value: string) {
        return value
          ? new Intl.DateTimeFormat('de-DE', {
              hour: '2-digit',
              minute: '2-digit'
            }).format(new Date(value))
          : void 0;
      }
    };
  }
} as Plugin;
