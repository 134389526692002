<template>
  <div class="filter" :class="props.hasSearchResult ? 'border-0 border-b border-solid border-zinc-200' : ''">
    <div class="inner">
      <NfInputFloatLabel input-name="from" :label="t('search.from.label')" class="flex-1">
        <Calendar id="from" v-model="fromValue" showButtonBar panelClass="!z-[99999]"></Calendar>
      </NfInputFloatLabel>
      <NfInputFloatLabel input-name="to" :label="t('search.to.label')" class="flex-1">
        <Calendar id="to" v-model="toValue" showButtonBar panelClass="!z-[99999]"></Calendar>
      </NfInputFloatLabel>
      <Dropdown :options="Object.values(SearchSort)" v-model="sortValue" panelClass="!z-[99999]">
        <template #value="slotProps">
          {{ slotProps.value ? t(`search.sort.${slotProps.value}.label`) : t('search.sort.placeholder.label') }}
        </template>
        <template #option="slotProps">
          {{ t(`search.sort.${slotProps.option}.label`) }}
        </template>
      </Dropdown>
    </div>
    <div></div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { SearchSort } from '@/types/contracts/generated/models/searchSort';
import NfInputFloatLabel from '@/components/form/NfInputFloatLabel.vue';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

// Define Component
const props = defineProps<{
  hasSearchResult?: Boolean;
}>();

// Stores
const { t } = useI18n();
const { value: sortValue } = useField<string>('sort');
const { value: fromValue } = useField<Date | undefined>('from');
const { value: toValue } = useField<Date | undefined>('to');
</script>

<style scoped>
.filter {
  @apply gap-2 px-4 py-5 md:flex-row lg:px-8 relative;

  &::after {
    @apply content-[''] absolute top-[calc(100%+1px)] left-0 w-full h-6 z-10;
    background: linear-gradient(0deg, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 100%);
  }

  & .inner {
    @apply flex w-full gap-2 items-start;

    :deep(.p-inputwrapper) {
      @apply w-full;
    }
  }
}

/* @todo: find better solution for breakpoint down */
@media (max-width: 554px) {
  :deep(.p-float-label .p-inputwrapper .p-inputtext),
  :deep(.p-float-label label) {
    @apply text-sm;
  }
}

:deep(.p-dropdown) {
  @apply flex-1;
}
</style>
