<template>
  <button
    type="button"
    class="shadow-button"
    :class="props.size < 24 ? 'btn-small' : ''"
    @click="(ev) => emits('click', ev)"
    :title="props.title || void 0"
  >
    <component :is="props.icon" :width="props.size" :height="props.size" />
  </button>
</template>

<script lang="ts">
import type { Component } from 'vue';

export interface ShadowButtonProps {
  /**
   * Shadow button component.
   */
  icon: Component;

  /**
   * The desired icon size, uses 24 per default.
   */
  size?: number;

  /**
   * Alternative title text to increase a11y.
   */
  title?: string;
}

export interface ShadowButtonEmits {
  /**
   * Click event handler
   * @param event
   */
  (ev: 'click', event: Event): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'ShadowButton'
}
</script>

<script lang="ts" setup>
// Define Component
const props = withDefaults(defineProps<ShadowButtonProps>(), {
  size: 24
});
const emits = defineEmits<ShadowButtonEmits>();
</script>

<style scoped>
.shadow-button {
  @apply w-10 h-10 flex items-center justify-center relative p-0 border-0 outline-none rounded-full cursor-pointer;
  @apply duration-300 ease-in-out transition-colors;
  @apply text-zinc-500 bg-transparent;

  &:hover {
    @apply text-zinc-700;
  }

  svg {
    @apply z-20 pointer-events-none;
  }

  &::before {
    @apply w-12 h-12 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none rounded-full z-10 scale-0;
    @apply bg-zinc-200/50;
    @apply duration-300 ease-in-out transition-transform;
    content: '';
  }

  &:hover::before {
    @apply scale-100;
  }

  &.btn-small {
    @apply w-8 h-8;

    &::before {
      @apply w-10 h-10;
    }
  }
}
</style>
