<template>
  <PrimeSidebar ref="sidebar" class="!bg-zinc-50" v-model:visible="sidebarOpen" position="right">
    <AppointmentsComponent :close="close" />
  </PrimeSidebar>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'CalendarSidebar'
}
</script>

<script lang="ts" setup>
import { provide, ref } from 'vue';
import PrimeSidebar from 'primevue/sidebar';
import AppointmentsComponent from '@/components/appointments/AppointmentsComponent.vue';

const sidebarOpen = ref<boolean>(false);
const sidebar = ref<InstanceType<typeof PrimeSidebar>>();

function open() {
  sidebarOpen.value = true;
}

function close() {
  sidebarOpen.value = false;
}

function toggle() {
  sidebarOpen.value = !sidebarOpen.value;
}

provide('calendar', {
  sidebar,
  isOpen: sidebarOpen,
  open,
  close,
  toggle
})

defineExpose({
  sidebar,
  isOpen: sidebarOpen,
  open,
  close,
  toggle
});
</script>

<style scoped>
.link-button {
  @apply text-primary-600;
}
</style>
