import type { AxiosResponse } from 'axios';
import type { AsslDto } from '@/types/contracts/generated/models/asslDto';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/assl`;
export function useCampusSupport() {
  const { makePostRequest, makeGetRequest, makeDeleteRequest } = useResource();

  const postSupportRequest = async (formData: FormData): Promise<AxiosResponse<Record<string, any>>> =>
    makePostRequest(`${baseUrl}`, formData);

  const getSupportRequests = async (): Promise<AsslDto[]> => makeGetRequest(`${baseUrl}`);

  const postSwitchToPerson = async (personId: number) => makePostRequest(`${baseUrl}/${personId}`, {});

  const suspendSwitchToPerson = async () => makeDeleteRequest(`${baseUrl}/suspend`);

  const completeSupportRequest = async () => makeDeleteRequest(`${baseUrl}/complete`);

  return {
    postSupportRequest,
    getSupportRequests,
    postSwitchToPerson,
    suspendSwitchToPerson,
    completeSupportRequest
  };
}
