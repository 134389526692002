<template>
  <div
    class="flex items-center gap-4 px-4 cursor-pointer fom-mobile-side-navigation__header group"
    @click="!mobileProfileMenuOpen && props.toggle()"
  >
    <div class="overflow-hidden bg-zinc-100 rounded-full w-14 h-14">
      <img
        v-if="props.compactProfile.photo"
        :src="props.compactProfile.photo"
        :alt="`Profile Image ${props.compactProfile?.vorname} ${props.compactProfile.nachname}`"
        class="object-cover transition-transform group-hover:scale-105 profile-image"
      />
      <PrimeSkeleton v-else class="!w-14 !h-14 rounded-full" />
    </div>
    <div class="flex items-center">
      <div class="profile-data">
        <div class="flex items-center gap-2 profile-data__name">
          <template v-if="props.compactProfile.vorname && props.compactProfile.nachname">
            <div class="flex flex-col gap-1">
              <div class="flex items-center gap-2 font-semibold">
                {{ `${props.compactProfile?.vorname} ${props.compactProfile.nachname}` }}
                <!-- <IconTextAccount v-if="!mobileProfileMenuOpen" class="w-4 h-4" /> -->
                <!-- <IconAccountOutline /> -->
                <!-- <IconAccountDetailsOutline /> -->
                <!-- <IconCardAccountDetailsOutline /> -->
              </div>
              <div class="text-sm profile-data__degree-info">
                {{
                  `${props.compactProfile?.institut} | ${props.compactProfile?.studiengangKuerzel} | ${props.compactProfile?.matrikelNummer}`
                }}
                <span class="block mt-1 text-primary-700" :class="!mobileProfileMenuOpen ? 'visible' : 'invisible'">{{
                  $t('mobile_navigation_profile')
                }}</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col gap-2">
              <div class="flex gap-2">
                <PrimeSkeleton class="!w-20 !h-3" />
                <PrimeSkeleton class="!w-28 !h-3" />
              </div>
              <div class="flex gap-2">
                <PrimeSkeleton class="!w-18 !h-2" />
                <PrimeSkeleton class="!w-18 !h-2" />
                <PrimeSkeleton class="!w-18 !h-2" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { KompaktProfilViewModel } from '@/types/contracts/generated/models/kompaktProfilViewModel';
import PrimeSkeleton from 'primevue/skeleton';
import IconAccountOutline from '~icons/mdi/account-outline';
import IconAccountDetailsOutline from '~icons/mdi/account-details-outline';
import IconAccountEyeOutline from '~icons/mdi/account-eye-outline';
import IconCardAccountDetailsOutline from '~icons/mdi/card-account-details-outline';
import IconTextAccount from '~icons/mdi/text-account';

const props = defineProps<{
  compactProfile: KompaktProfilViewModel;
  mobileProfileMenuOpen: Boolean;
  toggle: Function;
}>();
</script>
