<template>
  <NfErrorMessage>
    <span :class="[label ? 'p-float-label' : '']">
      <slot></slot>
      <label v-if="label" :for="inputName">{{ label }}</label>
    </span>
  </NfErrorMessage>
</template>

<script lang="ts">
export interface NfInputFloatLabelProps {
  inputName: string; 
  label?: string;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'NfInputFloatLabel'
}
</script>

<script lang="ts" setup>
import NfErrorMessage from '@/components/form/NfErrorMessage.vue';

// @todo Deprecated / Legacy Code
// Define Component
defineProps<NfInputFloatLabelProps>();
</script>

<style>
.thesischangepersonaldatadialog .p-float-label input:disabled.p-filled {
  @apply bg-white/75;
}
.thesischangepersonaldatadialog .p-float-label input:disabled.p-filled +label {
  @apply -translate-y-1/2 rounded-t-sm !text-zinc-600;
  background: rgb(244,244,245);
background: linear-gradient(0deg, rgba(250, 250, 250, 1) 0%, rgba(244, 244, 245, 1) 100%);
}
</style>
