<template>
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 427.41 313.47"
  >
    <defs>
      <mask id="mask" x="-28.09" y="64.2" width="480.04" height="249.26" maskUnits="userSpaceOnUse">
        <g id="mask0_6_15471" data-name="mask0 6 15471">
          <path d="m451.95,64.2H-28.09v249.26h480.04V64.2Z" style="fill: #fff; stroke-width: 0px" />
        </g>
      </mask>
      <mask id="mask-1" x="271.49" y="89.32" width="83.06" height="82.16" maskUnits="userSpaceOnUse">
        <g id="mask1_6_15471" data-name="mask1 6 15471">
          <path
            d="m283.88,158.92c4.86,4.93,10.82,8.58,17.38,10.65,6.56,2.06,13.52,2.48,20.27,1.2,6.75-1.28,13.1-4.2,18.48-8.53,5.39-4.32,9.66-9.91,12.44-16.29-7.81,3.51-16.48,4.54-24.87,2.95-8.39-1.59-16.11-5.72-22.15-11.85-6.04-6.13-10.1-13.96-11.67-22.48s-.55-17.32,2.9-25.24c-6.28,2.82-11.79,7.15-16.05,12.62-4.26,5.47-7.15,11.91-8.4,18.76-1.26,6.85-.86,13.91,1.18,20.57,2.03,6.66,5.64,12.71,10.49,17.64Z"
            style="fill: #fff; stroke-width: 0px"
          />
        </g>
      </mask>
    </defs>
    <path
      d="m419.98,33.08c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m392.42,33.08c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m419.98,5.51c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m392.42,5.51c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m419.98,88.22c1.52,0,2.76-1.24,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m392.42,88.22c1.52,0,2.76-1.24,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m419.98,60.65c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m392.42,60.65c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m419.98,142.66c1.52,0,2.76-1.24,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m392.42,142.66c1.52,0,2.76-1.24,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m419.98,115.09c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #767676; stroke-width: 0px"
    />
    <path
      d="m392.42,115.09c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.24-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m419.98,197.8c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m392.42,197.8c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m419.98,170.23c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m392.42,170.23c1.52,0,2.76-1.23,2.76-2.76s-1.23-2.76-2.76-2.76-2.76,1.23-2.76,2.76,1.23,2.76,2.76,2.76Z"
      style="fill: #0e5551; stroke-width: 0px"
    />
    <path
      d="m270.76,313.47c86.52,0,156.66-70.17,156.66-156.73S357.28,0,270.76,0,114.1,70.17,114.1,156.73s70.14,156.73,156.66,156.73Z"
      style="fill: #a0a09f; stroke-width: 0px"
    />
    <g style="mask: url(#mask)">
      <g>
        <path
          d="m384.91,115.36H11.27c-2.28,0-4.12,2.41-4.12,5.39v26.25c0,2.98,1.84,5.4,4.12,5.4h373.65c2.28,0,4.12-2.41,4.12-5.4v-26.25c0-2.98-1.85-5.39-4.12-5.39Z"
          style="fill: #e4e3e3; stroke-width: 0px"
        />
        <path
          d="m5.6,110.22h373.03c.15,0,.49.08.89.61.39.54.71,1.39.71,2.45v27.15c0,1.06-.32,1.92-.71,2.45s-.74.61-.89.61H5.6c-.15,0-.49-.08-.89-.61-.39-.53-.71-1.39-.71-2.45v-27.15c0-1.06.32-1.92.71-2.45.39-.53.74-.61.89-.61Z"
          style="fill: #f8f7f7; stroke: #bcbcbb; stroke-width: 8px"
        />
        <path
          d="m320.08,186.7c24.49,0,44.35-20.15,44.35-45.01s-19.85-45.01-44.35-45.01-44.35,20.15-44.35,45.01,19.85,45.01,44.35,45.01Z"
          style="fill: #f8f7f7; stroke-width: 0px"
        />
        <g style="mask: url(#mask-1)">
          <g>
            <path d="m276.97,156.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m276.97,140.89h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m276.97,125.14h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m276.97,109.39h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m276.97,93.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m292.48,156.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m292.48,140.89h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m292.48,125.14h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m292.48,109.39h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m292.48,93.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m308,156.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m308,140.89h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m308,125.14h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m308,109.39h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m308,93.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m323.52,156.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m323.52,140.89h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m323.52,125.14h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m323.52,109.39h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m323.52,93.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m339.04,156.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m339.04,140.89h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m339.04,125.14h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m339.04,109.39h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m339.04,93.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m354.55,156.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m354.55,140.89h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m354.55,125.14h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m354.55,109.39h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
            <path d="m354.55,93.64h-4.43v4.5h4.43v-4.5Z" style="fill: #bcbcbb; stroke-width: 0px" />
          </g>
        </g>
        <path
          d="m361.72,179.22c-.53,0-1.05-.1-1.55-.3-.49-.19-.93-.48-1.31-.84l-16.47-15.86c-.75-.73-1.17-1.72-1.17-2.75,0-1.03.43-2.01,1.18-2.74.76-.73,1.78-1.14,2.85-1.14,1.07,0,2.09.4,2.85,1.13l16.47,15.86c.57.54.95,1.24,1.11,1.99.15.76.07,1.54-.23,2.25-.3.71-.82,1.32-1.49,1.75-.66.43-1.44.66-2.24.66Z"
          style="fill: #bcbcbb; stroke-width: 0px"
        />
        <path
          d="m360.46,172.46l-2.67,2.77c-2.8,2.91-2.8,7.62,0,10.52l35.23,36.62c2.8,2.91,7.33,2.91,10.13,0l2.67-2.77c2.8-2.91,2.8-7.62,0-10.53l-35.23-36.62c-2.8-2.91-7.33-2.91-10.13,0Z"
          style="fill: #f8f7f7; stroke-width: 0px"
        />
        <path
          d="m398.85,227.76c-1.4,0-2.79-.27-4.09-.8-1.3-.53-2.48-1.32-3.46-2.31l-36.88-36.66c-.99-.99-1.78-2.16-2.32-3.44-.54-1.29-.81-2.67-.81-4.06s.28-2.77.81-4.06c.54-1.29,1.32-2.46,2.32-3.44l2.79-2.78c2-1.99,4.72-3.1,7.55-3.1s5.55,1.12,7.55,3.1l36.88,36.67c2,1.99,3.12,4.69,3.12,7.51s-1.12,5.52-3.12,7.51l-2.8,2.78c-.99.99-2.17,1.77-3.46,2.3-1.29.53-2.68.8-4.09.8Zm-34.09-54.34c-.57,0-1.13.11-1.65.32-.52.21-1,.53-1.4.93l-2.79,2.78c-.4.4-.72.87-.93,1.39-.22.52-.33,1.08-.33,1.64s.11,1.12.33,1.64c.22.52.53.99.93,1.39l36.88,36.66c.82.78,1.91,1.22,3.05,1.22s2.23-.43,3.05-1.22l2.8-2.78c.81-.81,1.26-1.9,1.26-3.03s-.45-2.22-1.26-3.03l-36.88-36.66c-.4-.4-.88-.71-1.4-.93-.52-.21-1.08-.33-1.65-.32Z"
          style="fill: #bcbcbb; stroke-width: 0px"
        />
        <path
          d="m313.98,170.92c29.5,0,38.72-14.37,41.72-42.51,0-30.23-14.18-44.98-43.68-44.98-25.51,3.98-41.25,12.91-41.25,43.14,3.3,30.89,13.71,44.34,43.22,44.34Z"
          style="fill: #f6f6f6; stroke-width: 0px"
        />
        <path
          d="m313.21,174.11c-7.6,0-15.09-1.9-21.79-5.54-6.7-3.64-12.42-8.9-16.65-15.32-4.22-6.42-6.82-13.8-7.57-21.48-.74-7.68.39-15.43,3.3-22.56s7.51-13.42,13.39-18.32c5.88-4.9,12.85-8.24,20.31-9.75,7.46-1.5,15.16-1.12,22.44,1.12,7.28,2.24,13.89,6.27,19.27,11.73,6.47,6.56,10.88,14.93,12.67,24.03,1.79,9.11.87,18.55-2.63,27.13-3.5,8.58-9.43,15.91-17.04,21.07-7.61,5.15-16.55,7.9-25.7,7.9Zm0-87.52c-9.25,0-18.21,3.27-25.36,9.23-7.15,5.96-12.04,14.26-13.83,23.47-1.8,9.21-.4,18.77,3.97,27.05,4.37,8.28,11.42,14.77,19.97,18.36,8.55,3.59,18.06,4.06,26.91,1.33,8.85-2.73,16.49-8.49,21.63-16.3,5.14-7.81,7.44-17.19,6.53-26.53-.91-9.35-4.98-18.08-11.53-24.72-3.71-3.77-8.12-6.76-12.98-8.8-4.85-2.04-10.06-3.08-15.31-3.07Z"
          style="fill: #bcbcbb; stroke-width: 0px"
        />
        <path
          d="m201.5,176.25H25.45c-1.07,0-1.94,1.25-1.94,2.79v13.58c0,1.54.87,2.79,1.94,2.79h176.05c1.07,0,1.94-1.25,1.94-2.79v-13.58c0-1.54-.87-2.79-1.94-2.79Z"
          style="fill: #e4e3e3; stroke-width: 0px"
        />
        <path
          d="m178.46,211.49H24.57c-.94,0-1.7,1.25-1.7,2.79v13.58c0,1.54.76,2.79,1.7,2.79h153.89c.94,0,1.7-1.25,1.7-2.79v-13.58c0-1.54-.76-2.79-1.7-2.79Z"
          style="fill: #e4e3e3; stroke-width: 0px"
        />
        <path d="m268.87,259.94l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
        <path d="m295.92,259.94l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
        <path d="m322.35,259.94l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
        <path d="m349.4,259.94l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
        <path d="m295.92,226.73l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
        <path d="m322.35,226.73l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
        <path d="m349.4,226.73l-10.07,8.94v-17.88l10.07,8.94Z" style="fill: #f8f7f7; stroke-width: 0px" />
      </g>
    </g>
    <path d="m342.59,143.34l-39.25-38.94-9.52,9.6,39.25,38.95,9.52-9.6Z" style="fill: #00a497; stroke-width: 0px" />
    <path d="m303.54,153.06l38.93-39.27-9.6-9.52-38.93,39.27,9.6,9.52Z" style="fill: #00a497; stroke-width: 0px" />
    <path d="m342.59,143.34l-39.25-38.94-9.52,9.6,39.25,38.95,9.52-9.6Z" style="fill: #e4e3e3; stroke-width: 0px" />
    <path d="m303.54,153.06l38.93-39.27-9.6-9.52-38.93,39.27,9.6,9.52Z" style="fill: #e4e3e3; stroke-width: 0px" />
    <path d="m336.31,140.54l-39.25-38.94-9.51,9.6,39.25,38.94,9.52-9.6Z" style="fill: #00a497; stroke-width: 0px" />
    <path d="m297.26,150.26l38.93-39.27-9.6-9.52-38.93,39.27,9.6,9.52Z" style="fill: #00a497; stroke-width: 0px" />
    <path d="m336.31,140.54l-39.25-38.94-9.51,9.6,39.25,38.94,9.52-9.6Z" style="fill: #a0a09f; stroke-width: 0px" />
    <path d="m297.26,150.26l38.93-39.27-9.6-9.52-38.93,39.27,9.6,9.52Z" style="fill: #a0a09f; stroke-width: 0px" />
  </svg>
</template>
