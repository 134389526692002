
import type { FlyoutDto } from '@/types/contracts/generated/models/flyoutDto';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/flyout`;

export function useCampusFlyout() {
  const { makeGetRequest } = useResource();

  const getFlyoutInfo = async (): Promise<FlyoutDto> => makeGetRequest(`${baseUrl}`);

  return {
    getFlyoutInfo
  };
}
