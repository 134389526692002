<template>
  <div class="header-title">
    <h1 class="mb-0 title-name">{{ title }}</h1>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

// Define Component
const props = defineProps<{
  name: any;
}>();

// Stores
const { t } = useI18n();

// States
const customTitle = ref<string|null>(null);
const title = computed<string>(() => {
  let title = customTitle.value || props.name || '';
  let translated = title.includes('meta.title') ? t(title) : title;
  if (translated?.startsWith('OC |')) {
    translated = translated.slice(5);
  }
  return (translated && translated.length > 0) ? translated : 'Online-Campus';
});

/**
 * Set custom title
 * @param newTitle 
 */
function setCustomTitle(newTitle: string|null) {
  customTitle.value = newTitle;
}

/**
 * Unset custom title
 */
function unsetCustomTitle() {
  customTitle.value = null;
}

// Expose Methods
defineExpose({
  setCustomTitle,
  unsetCustomTitle
});
</script>
