<template>
  <div class="input-wrapper">
    <div class="relative">
      <NfInputText
        v-model="search"
        :label="t('search.input.label')"
        input-name="search"
        class="input-field" />
      <IconClose class="input-clear" @click="search = ''" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSearchStore } from '@/stores/search';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import NfInputText from '@/components/form/NfInputText.vue';
import IconClose from '~icons/mdi/close';

const { t } = useI18n();
const { search } = storeToRefs(useSearchStore());
</script>

<style scoped>
.input-wrapper {
  @apply py-4 lg:pt-8 px-4 lg:px-8 !bg-zinc-50;

  :deep(.p-inputtext):focus {
    & + label {
      @apply bg-zinc-50;
    }
  }
}

.input-clear {
  @apply absolute top-0 text-zinc-300 translate-y-1/2 cursor-pointer right-2;
}
</style>
