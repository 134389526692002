<template>
  <div class="chevron-parent">
    <div :class="[roomData?.details && toggle ? 'roomdata-toggle' : '']" @click="toggleDetails">
      {{ roomData?.raum }}
      <IconChevronDown v-if="roomData?.details && toggle" :class="detailsVisible ? 'rotate-0' : 'rotate-90'" />
    </div>
    <div v-if="detailsVisible || !toggle">
      <div v-if="roomData.name">
        {{ roomData.name }}
      </div>
      <div v-if="roomData.strasse">
        {{ roomData.strasse }}
      </div>
      <div v-if="roomData.stadt && roomData.plz" class="roomdata-details">
        <span>{{ roomData.stadt }}</span>
        <span>{{ roomData.plz }}</span>
        <template v-if="roomData.land">
          <span>-</span>
          <span>{{ roomData.land }}</span>
        </template>
      </div>
      <div v-if="roomData.googlemapslink" class="roomdata-maps">
        <IconMapMarkerOutline />
        <a :href="roomData.googlemapslink" target="_blank">{{ $t('room.maps_link') }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'RoomComponent'
}
</script>

<script setup lang="ts">
import type { RaumDto } from '@/types/contracts/generated/models/raumDto';

import IconChevronDown from '~icons/mdi/chevron-down';
import IconMapMarkerOutline from '~icons/mdi/map-marker-outline';
import { ref } from 'vue';
import { useCampusUserApi } from '@/composables/http/use-campus-user-api';

// Define Component

/** @todo fix lazy load - pass values as properties / shared handling */
const props = defineProps<{
  room: RaumDto;
  toggle: boolean;
}>();

const roomData = ref(props.room);
const detailsVisible = ref(false);
const { fetchRoom } = useCampusUserApi();
if (roomData.value.id) {
  fetchRoom(roomData.value.id)
    .then((data) => {
      roomData.value = data;
    })
    .catch((err) => console.error(err));
}

const toggleDetails = () => {
  if (roomData.value.name) {
    detailsVisible.value = !detailsVisible.value;
  }
};
</script>

<style scoped>
.roomdata-toggle {
  @apply cursor-pointer text-primary-700 flex items-center;
}

.roomdata-details {
  @apply flex flex-row gap-2;
}

.roomdata-maps {
  @apply flex flex-row items-center gap-1 mb-2;
}
</style>
