import type { AxiosError, AxiosResponse } from 'axios';
import type { ConstraintViolation } from '@/models/error/constraint-violation';

import { HttpStatusCode } from 'axios';
import { NotFoundProblem } from '@/models/error/not-found-problem';
import { ConstraintViolationProblem } from '@/models/error/constraint-violation-problem';
import { UnauthorizedProblem } from '@/models/error/unauthorized-problem';
import { ForbiddenProblem } from '@/models/error/forbidden-problem';
import { Problem } from '@/models/error/problem';
import { BadRequestProblem } from '@/models/error/bad-request-problem';
import { ConflictProblem } from '@/models/error/conflict-problem';

export const parseResponseProblem = (reason: AxiosError) => {
  {
    //console.error(reason);
    const response = reason.response as AxiosResponse<Record<string, any>>;
    if (response?.status === HttpStatusCode.NotFound) {
      const problemData: NotFoundProblem = response.data as NotFoundProblem;
      throw new NotFoundProblem(
        problemData.message ?? problemData.detail ?? 'Nicht gefunden',
        problemData.title,
        problemData.instance,
        problemData.detail,
        reason
      );
    }
    if (response?.status === HttpStatusCode.Conflict) {
      const problemData: ConflictProblem = response.data as ConflictProblem;
      throw new ConflictProblem(
        problemData.message ?? problemData.detail ?? 'Konflikt',
        problemData.title,
        problemData.instance,
        problemData.type,
        reason
      );
    }

    if (response?.status === HttpStatusCode.BadRequest) {
      if (response.data['problem-type']) {
        const problemData: BadRequestProblem = response.data as BadRequestProblem;
        throw new BadRequestProblem(
          problemData.message ?? problemData.detail,
          problemData.title,
          problemData.instance,
          problemData.type,
          problemData.violations as ConstraintViolation[],
          reason
        );
      } else {
        const problemData: ConstraintViolationProblem = response.data as ConstraintViolationProblem;
        throw new ConstraintViolationProblem(
          problemData.message ?? problemData.detail ?? 'Fehler',
          problemData.title,
          problemData.instance,
          problemData.violations,
          reason
        );
      }
    }
    if (response?.status === HttpStatusCode.Unauthorized) {
      const problemData: UnauthorizedProblem = response.data as UnauthorizedProblem;
      throw new UnauthorizedProblem(
        problemData.message ?? problemData.detail ?? 'Nicht autorisiert',
        problemData.title,
        problemData.instance,
        reason
      );
    }
    if (response?.status === HttpStatusCode.Forbidden) {
      const problemData: ForbiddenProblem = response.data as ForbiddenProblem;
      throw new ForbiddenProblem(
        problemData.message ?? problemData.detail ?? 'Nicht autorisiert',
        problemData.title,
        problemData.instance,
        reason
      );
    }
    if (response?.data) {
      const problemData: Problem = response.data as Problem;
      throw new Problem(
        problemData.message ??
          problemData.detail ??
          `Unbekanntes Problem. Status Code: ${response.status ?? HttpStatusCode.BadRequest}`,
        response.status ?? HttpStatusCode.BadRequest,
        problemData.title,
        problemData.instance,
        reason
      );
    }
    console.error(reason);
    throw new Problem(
      `Unbekanntes Problem. Status Code: ${response?.status ?? HttpStatusCode.BadRequest}`,
      response?.status ?? HttpStatusCode.BadRequest,
      'Unknown',
      '',
      reason
    );
  }
};
