import { Problem } from '@/models/error/problem';
import { HttpStatusCode } from 'axios';
import type { ConstraintViolation } from '@/models/error/constraint-violation';
export interface BadRequestProblem extends Problem {}

export class BadRequestProblem extends Problem {
  private readonly _type: string;
  private readonly _violations: ConstraintViolation[] | undefined;

  constructor(message: string, title: string, instance: string, type: string, violations: any[], baseError: Error) {
    super(message, HttpStatusCode.BadRequest, title, instance, baseError);
    this._type = type;
    this._violations = violations;
  }

  get type(): string {
    return this._type;
  }

  get violations(): ConstraintViolation[] | undefined {
    return this._violations;
  }
}
