<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.size || 24"
    :height="props.size || 24"
    :fill="props.fill || 'currentColor'"
    viewBox="0 0 24 24"
  >
    <path
      d="M20,20H7a2,2,0,0,1-2-2V8.94L2.23,5.64A1.021,1.021,0,0,1,2,5,1,1,0,0,1,3,4H20a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2M8.5,7a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.5m0,4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h10a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5H8.5m0,4a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5Z"
      transform="translate(-1)"
    />
  </svg>
</template>

<script lang="ts">
export interface IconProps {
  /**
   * Icon Size.
   */
  size?: number | string;

  /**
   * Icon Fill Color.
   */
  fill?: string;
}
</script>

<script lang="ts" setup>
// Define Component
const props = defineProps<IconProps>();
</script>
