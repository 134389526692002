<template>
  <ButtonStd
    severity="secondary"
    :icon="IconArrowLeft"
    @click="(ev: Event) => emits('click', ev)"
    class="back-button"
    :label="props.label"
    :disabled="props.disabled || void 0"
    :loading="props.loading || void 0"
  />
</template>

<script lang="ts">
export interface BackButtonProps {
  /**
   * The desired PrimeVue button label.
   */
  label: string;

  /**
   * Button loading date.
   */
  loading?: boolean;

  /**
   * Button disabled date.
   */
  disabled?: boolean;
}

export interface BackButtonSlots {
  /**
   * Click Event Handler
   * @param event
   */
  (ev: 'click', event: Event): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'BackButton'
}
</script>

<script lang="ts" setup>
import IconArrowLeft from '~icons/mdi/arrow-left';
import ButtonStd from '@/components/buttons/ButtonStd.vue';

// Define Component
const props = defineProps<BackButtonProps>();
const emits = defineEmits<BackButtonSlots>();
</script>

<style scoped>
.p-button.p-button-secondary.back-button:not(:hover):not(:disabled):not(:active) {
  @apply !bg-transparent p-0;
}
.p-button.p-button-secondary.back-button:not(:disabled) {
  transition-property: background-color, border-color, transform, box-shadow, padding;
  :deep(svg) {
    @apply duration-300 transition-colors;
    /* @apply transform -translate-x-px duration-300 transition-transform; */
  }
}

.p-button.p-button-secondary.back-button:not(:disabled):hover {
  @apply bg-transparent text-primary-500 p-0;

  
  :deep(svg) {
    @apply duration-300 transition-colors;
    /* @apply transform -translate-x-px duration-300 transition-transform; */
  }
}
</style>
