import type { CalendarDto } from '@/types/contracts/generated/models/calendarDto';
import { defineStore } from 'pinia';
import { useCampusUserApi } from '@/composables/http/use-campus-user-api';

interface State {
  calendar?: CalendarDto;
}

export const useCalendarStore = defineStore('calendar', {
  state: (): State => ({
    calendar: undefined
  }),
  actions: {
    async fetchCalendarData() {
      const { fetchCalendar } = useCampusUserApi();
      try {
        this.calendar = await fetchCalendar();
      } catch (e) {
        console.error(e);
      }
    }
  }
});
