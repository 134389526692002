import { Problem } from '@/models/error/problem';
import type { ConstraintViolationItem } from '@/models/error/constraint-violation-item';
import { HttpStatusCode } from 'axios';

export interface ConstraintViolationProblem extends Problem {}

export class ConstraintViolationProblem extends Problem {
  protected _violations: ConstraintViolationItem[];
  constructor(
    message: string,
    title: string,
    instance: string,
    violations: ConstraintViolationItem[],
    baseError: Error
  ) {
    super(message, HttpStatusCode.BadRequest, title, instance, baseError);
    this._violations = violations;
  }

  get violations(): ConstraintViolationItem[] {
    return this._violations;
  }
}
