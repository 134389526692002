<template>
  <div @click="props.toggle()" class="flex items-center gap-2 p-4 cursor-pointer text-primary-500">
    <IconArrowLeft class="text-primary-500" />
    {{ $t('goBack') }}
  </div>
  <PanelMenu v-if="props.mobileProfileMenu" :model="props.mobileProfileMenu" v-model:expandedKeys="expandedKeysProfile">
    <template #item="{ item }">
      <div v-if="item.route" :class="[item.key?.split('_').length === 2 ? 'p-panelmenu-header-action' : '']">
        <RouterLink :to="item.route" class="p-menuitem-link">
          {{ item.label }}
        </RouterLink>
      </div>
      <div
        v-else
        class="flex justify-between"
        :class="[item.key?.split('_').length === 2 ? 'p-panelmenu-header-action' : 'p-menuitem-link']"
      >
        <span>{{ item.label }}</span>
        <i v-if="item.items && item.key && expandedKeysProfile[item.key]" class="pi pi-chevron-down" />
        <i v-else-if="item.items" class="pi pi-chevron-right" />
      </div>
    </template>
  </PanelMenu>
  <div v-else class="flex flex-col gap-8 p-4 mt-4">
    <PrimeSkeleton class="!w-48 !h-4" />
    <PrimeSkeleton class="!w-56 !h-4" />
    <PrimeSkeleton class="!w-52 !h-4" />
    <PrimeSkeleton class="!w-40 !h-4" />
    <PrimeSkeleton class="!w-32 !h-4" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { MenuItem } from 'primevue/menuitem';
import PanelMenu from 'primevue/panelmenu';
import PrimeSkeleton from 'primevue/skeleton';
import IconArrowLeft from '~icons/mdi/arrow-left';

const expandedKeysProfile = ref<Record<string, boolean>>({});
const props = defineProps<{
  mobileProfileMenu: MenuItem[];
  toggle: Function;
}>();
</script>
