<template>
  <div class="label">
    <PrimeCheckbox
      :name="props.name ? (props.name as string) : void 0"
      :disabled="props.disabled"
      :required="props.required"
      :class="{ 'p-invalid': props.invalid }"
      v-model="value"
      :binary="true"
    />
    <span v-if="props.label" @click="value = !value">
      {{ props.label }}
    </span>
  </div>
</template>

<script lang="ts">
import type { MaybeRef } from 'vue';

export interface CheckboxFieldProps {
  /**
   * A custom label string to be shown next to the Checkbox component.
   */
  label: string;

  /**
   * A unique field id, may already be generated by the parent FormControl component.
   */
  id: string;

  /**
   * The desired field value.
   */
  modelValue?: boolean;

  /**
   * The desired field name.
   */
  name?: MaybeRef<string> | string;

  /**
   * The field disabled state.
   */
  disabled?: boolean;

  /**
   * The field required state.
   */
  required?: boolean;

  /**
   * The field invalid state.
   */
  invalid?: boolean;
}

export interface CheckboxFieldEmits {
  /**
   * Update model value handler.
   */
  (ev: 'update:modelValue', value: boolean): void;
}
</script>

<script lang="ts" setup>
import PrimeCheckbox from 'primevue/checkbox';
import { computed } from 'vue';

// Define Component
const props = defineProps<CheckboxFieldProps>();
const emits = defineEmits<CheckboxFieldEmits>();

// States
const value = computed({
  get() {
    return props.modelValue || false;
  },
  set(value) {
    emits('update:modelValue', value);
  }
});
</script>

<style scoped>
.label {
  @apply flex flex-row gap-3 cursor-pointer;
}

span {
  @apply text-sm;
}
</style>
