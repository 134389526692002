<template>
  <PrimeDialog
    class="base-dialog"
    :class="[props.size ? `base-dialog-${props.size}` : '']"
    :header="props.title || void 0"
    :show-header="!!props.title || !!$slots.header"
    :draggable="false"
    :modal="true"
    :close-on-escape="props.escapable"
    :closable="props.closable"
    :dismissable-mask="props.dismissable"
    v-model:visible="isVisible"
  >
    <template v-if="!props.title && $slots.header" #header>
      <slot name="header" v-bind="props" />
    </template>

    <template v-if="$slots.default" #default>
      <slot name="default" v-bind="props" />
    </template>

    <template v-if="props.actions && props.actions.length > 0" #footer>
      <div class="dialog-footer-actions">
        <ButtonGroup gap="small" connected>
          <ButtonStd
            v-for="(action, idx) of props.actions"
            :key="idx"
            :severity="action.type"
            :label="action.label"
            @click.prevent="() => onAction(action.onClick)"
            :disabled="action.disabled || disabled"
            :loading="action.loading || void 0"
          />
        </ButtonGroup>
      </div>
    </template>

    <template v-else-if="$slots.footer" #footer>
      <slot name="footer" v-bind="props" />
    </template>
  </PrimeDialog>
</template>

<script lang="ts">
export type ActionCallback = void | boolean | Promise<void | boolean>;

export interface BaseDialogAction {
  type: 'primary' | 'secondary' | 'danger';
  label: string;
  onClick(): ActionCallback;
  disabled?: boolean;
  loading?: boolean;
}

export interface BaseDialogProps {
  /**
   * Dialog Visibility
   */
  visible: boolean;

  /**
   * Dialog width.
   */
  size?: 'small' | 'large';

  /**
   * Dialog Title, used instead of the header slot.
   */
  title?: string | null;

  /**
   * Dialog actions, used instead of the footer slot.
   */
  actions?: BaseDialogAction[];

  /**
   * Adds a close icon to the modal header.
   */
  closable?: boolean;

  /**
   * Dialog can be closed using ESC key.
   */
  escapable?: boolean;

  /**
   * Dialog can be closed by clicking outside of it.
   */
  dismissable?: boolean;
}

export interface BaseDialogEmits {
  /**
   * Change Modal Visibility Event Listener
   */
  (ev: 'update:visible', value: boolean): void;

  /**
   * Change Modal Visibility Event Listener
   */
  (ev: 'success', value: boolean): void;
}

export interface BaseDialogSlots {
  /**
   * Dialog Content
   * @param props
   */
  default(props: BaseDialogProps): any;

  /**
   * Dialog Header
   * @param props
   */
  header(props: BaseDialogProps): any;

  /**
   * Dialog Footer
   * @param props
   */
  footer(props: BaseDialogProps): any;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'BaseDialog'
}
</script>

<script lang="ts" setup>
import PrimeDialog from 'primevue/dialog';
import { computed, ref } from 'vue';

import ButtonGroup from '@/components/buttons/ButtonGroup.vue';
import ButtonStd from '@/components/buttons/ButtonStd.vue';

// Define Component
const props = withDefaults(defineProps<BaseDialogProps>(), {
  closable: false,
  escapable: true,
  dismissable: true
});
const emits = defineEmits<BaseDialogEmits>();
defineSlots<BaseDialogSlots>();

// States
const disabled = ref<boolean>(false);
const isVisible = computed({
  get() {
    return props.visible;
  },
  set(value) {
    emits('update:visible', value);
  }
});

/**
 * Handle Action
 * @param callback
 */
async function onAction(callback: () => ActionCallback) {
  let preventClose = false;
  try {
    let result = callback();
    if (result instanceof Promise) {
      disabled.value = true;
      preventClose = (await result) === false;
    } else {
      preventClose = result === false;
    }
  } catch (err) {
    console.error(err);
  } finally {
    disabled.value = false;
  }

  if (preventClose === false) {
    isVisible.value = false;
  }
}
</script>

<style>
.p-dialog.base-dialog .p-hidden-accessible + .p-dialog-content {
  @apply rounded-t-md;
}

.p-dialog.base-dialog {
  min-width: 400px;

  &.base-dialog-small {
    min-width: 300px;
  }

  &.base-dialog-large {
    min-width: 650px;
  }
}

.p-dialog.base-dialog .p-dialog-footer {
  @apply border-0;
}
</style>
