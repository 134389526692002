import type { SocketDataDto } from '@/types/contracts/generated/models/socketDataDto';
import { SocketAction } from '@/types/contracts/generated/models/socketAction';

import { useWebSocket } from '@vueuse/core';
import { useInfoStore } from '@/stores/info';
import { useVideoProcessingStore } from '@/stores/video-processing';
import { useCalendarStore } from '@/stores/calendar';
import {useDialog} from "primevue/usedialog";
import AppUpdateDialog from "@/components/primevue/GlobalAppUpdateDialog.vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import {useMiscStore} from "@/stores/misc";

export function useBackgroundWebsocket(url: string) {
  const { data, ws, send, status, open, close } = useWebSocket(`${url}`, {
    immediate: false,
    autoReconnect: {
      delay: Math.random() * (60000 - 5000) + 5000
    },
    heartbeat: {
      interval: 10000,
      pongTimeout: 1000
    },
    onError: (ws, err) => {
      console.error(err);
    },
    onDisconnected: (ws, event) => {
      console.error(event);
    },
    onMessage: (ws, event) => {
      if (event.data !== 'pong') {
        const msgObject = JSON.parse(event.data) as SocketDataDto;
        if (msgObject) {
          switch (msgObject.action) {
            case SocketAction.Newinfo: {
              const infoStore = useInfoStore();
              infoStore.fetchInfoLists();
              break;
            }
            case SocketAction.Video: {
              const { updateFile } = useVideoProcessingStore();
              updateFile(msgObject.id ?? '');
              break;
            }
            case SocketAction.Calendar: {
              const { fetchCalendarData } = useCalendarStore();
              fetchCalendarData();
              break;
            }
            case SocketAction.Hiddenrefresh: {
              window.location.reload();
              break;
            }
            case SocketAction.Dialogrefresh: {
              const {showAppUpdateDialog} = storeToRefs(useMiscStore());
              showAppUpdateDialog.value = true;
              break;
            }
          }
        }
      }
    }
  });

  return {
    data,
    ws,
    send,
    status,
    open,
    close
  };
}
