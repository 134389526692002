import { DeviceState } from '@/models/DeviceState';

import { useWindowSize } from '@vueuse/core';
import { defineStore } from 'pinia';
import { klona } from 'klona';
import { readonly } from 'vue';

const { width } = useWindowSize();

interface State {
  deviceState: DeviceState;
  mobileMenuOpen: boolean;
  mobileProfileMenuOpen: boolean;
  mobileCalendarOpen: boolean;
  historyStart: { length: number };
  isAssl: boolean;
  dashboardSelectedCourseOfStudies: number;
  showAppUpdateDialog: boolean;
}

export const useMiscStore = defineStore('misc', {
  state: (): State => ({
    deviceState: width.value < 992 ? DeviceState.MOBILE : DeviceState.DESKTOP,
    mobileMenuOpen: false,
    mobileProfileMenuOpen: false,
    mobileCalendarOpen: false,
    historyStart: readonly<{ length: number }>({ length: klona(window.history.length) }),
    isAssl: false,
    dashboardSelectedCourseOfStudies: -1,
    showAppUpdateDialog: false,
  }),
  getters: {
    hasHistory: (state) => {
      return window.history.length != state.historyStart.length;
    }
  },
  actions: {}
});
