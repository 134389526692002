<template>
  <div :class="[ doesDialog ? 'inline-flex items-center' : '']">
    <template v-if="person && !(props.options && props.options.image)">
        
        <!-- @todo remove quickfix & refactor person component -->
        <a v-if="options && options?.courseTable" @click="onAction" :class="[ doesDialog ? 'cursor-pointer' : '']">
          {{ `${person?.nachname}, ${person?.vorname}`.trim() }}
        </a>
        
        <a v-else @click="onAction" :class="[ doesDialog ? 'cursor-pointer' : '']">
          <span :class="doesDialog ? `text-primary-800 namesize leading-4 block` : 'text-zinc-700'">
            {{ `${person?.titelDe ?? ''} ${person?.name} ${person?.titelInt ?? ''}`.trim() }}
          </span>
        </a>
    </template>

    <div v-else :class="[options?.isInModal || !doesDialog ? '' : 'cursor-pointer dialog-link inline-flex mr-1 text-primary-700']" @click="() => {
        if (doesDialog) {
            profileVisible = true;
        }
    }">
      <template v-if="person">
        <div v-if="options && options.image" :class="options.isInModal ? 'items-start lg:items-center gap-4' : 'items-center gap-2 '" class="flex flex-row">
          <div class="flex items-center justify-center flex-shrink-0 rounded-full" :class="[options?.inline ? 'w-8 h-8 bg-zinc-100' : 'w-12 h-12 lg:w-16 lg:h-16', options?.size == 'xs' ? '!w-6 !h-6' : options?.size == 'sm' ? '!w-8 !h-8' : '']">
            <ImageComponent :class="[options?.inline ? 'w-8 h-8' : 'w-12 h-12 lg:w-16 lg:h-16', 'rounded-full object-cover bg-zinc-50', options?.size == 'xs' ? '!w-6 !h-6' : options?.size == 'sm' ? '!w-8 !h-8' : '']" :src="!isStorybook ? portraitUrl : ''" />
          </div>

          <div v-if="options && options.inline">
            <span :class="options?.size == 'xs' ? 'text-sm font-semibold' : options?.size == 'sm' ? '!text-md' : ''">{{ `${person?.titelDe ?? ''} ${person?.name} ${person?.titelInt ?? ''}`.trim() }}</span>
          </div>

         

          <div v-else class="flex justify-center text-zinc-700" :class="options?.size == 'xs' ? 'flex-row' : 'flex-col'">
            <span class="font-semibold" v-if="options && options.position">{{ options.position }}</span>
            <div :class="options?.size == 'xs' ? `flex items-center text-sm font-semibold ${person?.titelDe || person?.titelInt && 'mr-1'}` : options?.size == 'sm' ? '!text-md' : 'flex items-center personbadge'" v-else>
              {{ `${person?.titelDe ?? ''} ${person?.titelInt ?? ''}`.trim() }}
            </div>

            <div class="flex flex-row items-center gap-2 text-lg font-bold text-zinc-700 personcard" :class="options?.size == 'xs' ? 'text-sm font-semibold' : options?.size == 'sm' ? '!text-md' : ''">
              <span class="inline-block">{{ person?.name }}</span>
              <IconAccountPlus v-if="options?.isInModal && !options.isBuddy" v-tooltip.bottom-start="t('info.buddy.add_buddy_button_label')" class="flex h-6 align-text-bottom cursor-pointer text-md text-primary" @click="onAddBuddy()" />
              <IconAccountMinus v-if="options?.isInModal && options.isBuddy" v-tooltip.bottom-start="t('info.buddy.remove_buddy_button_label')" class="flex h-6 align-text-bottom cursor-pointer text-md text-primary" @click="onRemoveBuddy()" />
            </div>

            <div v-if="options?.isInModal && options?.socials" class="flex flex-row gap-3">

              <a v-if="getSocialLink('webseite') !== ''" :href="getSocialLink('webseite')" target="_blank">
                <IconWeb class="text-primary" />
              </a>

              <a v-if="getSocialLink('linkedin') !== ''" :href="getSocialLink('linkedin')" target="_blank">
                <IconLinkedin class="text-primary" />
              </a>

              <a v-if="getSocialLink('twitter') !== ''" :href="getSocialLink('twitter')" target="_blank">
                <IconTwitter class="text-primary" />
              </a>

              <a v-if="getSocialLink('messenger (skype)') !== ''" target="_blank" v-tooltip="{content: getSocialLink('messenger (skype)')}" class="hover:!cursor-default">
                <MdiSkype class="text-primary" />
              </a>

              <a v-if="getSocialLink('youtube') !== ''" :href="getSocialLink('youtube')" target="_blank">
                <MdiYoutube class="text-primary" />
              </a>

            </div>
          </div>
        </div>
      </template>
    </div>

    <!--  @vue-ignore-->
    <PublicProfileDialog v-if="person && profileVisible" :visible="profileVisible" :options="dialogOptions" :person-id="personId" @close="profileVisible = false" />
  </div>
</template>

<script lang="ts">
// Default Export, used for IDE auto-importing only
export default {
  name: 'PersonComponent'
}
</script>

<script lang="ts" setup>
import type { KommunikationDto } from "@/types/contracts/generated/models/kommunikationDto";

import IconAccountPlus from '~icons/mdi/account-plus';
import IconAccountMinus from '~icons/mdi/account-minus';
import IconWeb from '~icons/mdi/web';
import IconLinkedin from '~icons/mdi/linkedin';
import IconTwitter from '~icons/mdi/twitter';
import MdiYoutube from '~icons/mdi/youtube'
import MdiSkype from '~icons/mdi/skype'
import { useToast } from "primevue/usetoast";
import { computed, inject, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import ImageComponent from "@/components/common/ImageComponent.vue";
import PublicProfileDialog from "@/components/shared/PublicProfileDialog.vue";
import type CalendarSidebar from '@/components/sidebars/CalendarSidebar.vue';
import { useInfoStore } from "@/stores/info";
import { usePersonStore } from "@/stores/person";

const isStorybook = (import.meta.env?.STORYBOOK || 'false') == 'true'

const { t } = useI18n();
const toast = useToast();
const { addBuddy, removeBuddy } = useInfoStore();

const props = withDefaults(defineProps<{
    nameSize?: string,
    personId: number,
    options?: {
        image?: boolean,
        inline?: boolean,
        isInModal?: boolean,
        socials?: KommunikationDto[],
        isBuddy?: boolean,
        size?: string,
        position?: string
        // @todo remove snowflake :(
        courseTable?: boolean,
    },
    dialogOptions?: {
        displayLecture: boolean
    },
    /*@todo remove a tag ( hover interaction when set to false) */
    doesDialog?: boolean,
}>(), { doesDialog: false, dialogOptions: () => ({ displayLecture: false }) });

const portraitUrl = ref(`${import.meta.env.VITE_API_CAMPUS}/person/portrait/${props.personId}`);
const { fetchById, getById } = usePersonStore();
const profileVisible = ref<boolean | null>(false);
const calendarSidebar = inject<InstanceType<typeof CalendarSidebar>|null>('calendar', null);

watch(() => props.personId, newValue => {
    fetchById(newValue);
    portraitUrl.value = `${import.meta.env.VITE_API_CAMPUS}/person/portrait/${newValue}`;
}, { immediate: true });

const person = computed(() => !isStorybook ? getById(props.personId) : storybookPerson);


const getSocialLink = (name: string): string => {
    if (props.options?.socials) {
        return props.options.socials.find(entry => entry.name?.trim().toLowerCase() === name)?.value ?? '';
    }
    return '';
}

const onAddBuddy = () => {
    addBuddy(props.personId).then(() => {
        toast.add({ severity: "success", summary: t('info.buddy.add_successful'), life: import.meta.env.VITE_TOAST_FIXED_LIFETIME ? import.meta.env.VITE_TOAST_FIXED_LIFETIME_VALUE : undefined });
    }).catch((err: any) => console.error(err));
}

const onRemoveBuddy = () => {
    removeBuddy(props.personId).then(() => {
        toast.add({ severity: "success", summary: t('info.buddy.remove_successful'), life: import.meta.env.VITE_TOAST_FIXED_LIFETIME ? import.meta.env.VITE_TOAST_FIXED_LIFETIME_VALUE : undefined });
    }).catch((err: any) => console.error(err));
}

async function onAction() {
  if (!props.doesDialog) {
    return;
  }

  // @todo dirty quick-hack, PublicProfileDialog appears behind the CalendarSidebar, BUT disappears
  // when the sidebar is closed in favor to the dialog. Thus we change the z-index temporary.
  if (calendarSidebar && calendarSidebar.isOpen) {
    (calendarSidebar.sidebar as any).value.container.parentElement.style.setProperty('z-index', '500', 'important');
  }
  profileVisible.value = true;
}

const storybookPerson = {
  "id": 385597,
  "geschlecht": "M",
  "vorname": "John",
  "nachname": "Doe",
  "name": "John Doe",
  "titelDe": "Prof. Dr.",
  "titelInt": "",
  "showPortrait": false,
  "transientLastnameFirst": "John, Doe"
}
</script>

<style scoped></style>
