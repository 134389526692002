<template>
  <span>{{ $d(date, props.format) }}</span>
</template>

<script lang="ts">
export interface DateTimeProps {
  /**
   * A date object or date/time string.
   */
  date: Date | string;

  /**
   * One of the declared format to use.
   */
  format: string;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'DateTime'
}
</script>

<script lang="ts" setup>
import { computed } from 'vue';

// Define Component
const props = defineProps<DateTimeProps>();

// States
const date = computed(() => {
  return props.date instanceof Date ? props.date : new Date(props.date);
});
</script>

<style scoped></style>
