<template>
  <img v-if="srcWithToken" :src="srcWithToken" :alt="props.alt" />
  <div v-else-if="!srcWithToken && isStorybook"></div>
</template>

<script lang="ts">
export interface ImageComponentProps {
  /**
   * Desired image source.
   */
  src: string;

  /**
   * Alternative text for the image.
   */
  alt?: string;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'ImageComponent'
}
</script>

<script lang="ts" setup>
import { useDownload } from '@/composables/use-download';
import { useDownloadCenter } from '@/composables/use-download-center';
import { ref, watch } from 'vue';

// Define Component
const props = defineProps<ImageComponentProps>();

// States
const srcWithToken = ref<string>();

// Check if opened in Storybook
const isStorybook = (import.meta.env?.STORYBOOK || 'false') == 'true'

/**
 * Watch add auth token
 */
watch(
  () => props.src,
  async (newValue) => {
    if (!isNaN(parseInt(newValue))) {
      srcWithToken.value = await useDownloadCenter().displayImage(newValue);
    } else if (/(https?:\/\/)?(oc-digital\.de|fomdigital\.bildungscentrum\.de|campusdev\.by\.nf)/gi.test(newValue)) {
      srcWithToken.value = await useDownload().displayImage(newValue);
    } else {
      srcWithToken.value = newValue;
    }
  },
  { immediate: true }
);
</script>

<style scoped></style>
