import type { FileDto } from '@/types/contracts/generated/models/fileDto';

import { useDownload } from '@/composables/use-download';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/cms`;

export function useDownloadCenter() {
  const {
    downloadItem: downloadCenterItem,
    displayImage: displayDownloadCenterImage,
    displayItemData: displayDownloadCenterItemData,
    getDownloadItemUrl: displayDownloadCenterItemUrl
  } = useDownload();

  const { makeGetRequest } = useResource();

  const getDownloadItemUrl = async (docId: string | number): Promise<string> =>
    displayDownloadCenterItemUrl(`${baseUrl}/download/${docId}`);

  const downloadItem = async (docId: string | number): Promise<void> =>
    downloadCenterItem(`${baseUrl}/download/${docId}`);
  const displayImage = async (docId: string | number): Promise<string> =>
    displayDownloadCenterImage(`${baseUrl}/download/${docId}`);

  const displayItemData = async (docId: string | number): Promise<FileDto> =>
    displayDownloadCenterItemData(`${baseUrl}/downloadinfo/${docId}`);

  const searchDownloadCenter = async (query: string): Promise<FileDto[]> =>
    makeGetRequest(`${baseUrl}/downloadsearch`, { params: { query } });

  return {
    downloadItem,
    displayImage,
    displayItemData,
    searchDownloadCenter,
    getDownloadItemUrl
  };
}
