import { Problem } from '@/models/error/problem';
import { HttpStatusCode } from 'axios';

export interface ConflictProblem extends Problem {}

export class ConflictProblem extends Problem {
  private _type: string;

  get type(): string {
    return this._type;
  }

  constructor(message: string, title: string, instance: string, type: string, baseError: Error) {
    super(message, HttpStatusCode.Conflict, title, instance, baseError);
    this._type = type;
  }
}
