<template>
  <div class="timestamp" v-if="props.from">
    <DateTime :date="props.from" :format="props.format" />
    <span>&#160;</span>
    <span>{{ $t('calendar.clock') }}</span>

    <template v-if="props.to">
      <span> - </span>
      <DateTime :date="props.to" :format="props.format" />
      <span>&#160;</span>
      <span>{{ $t('calendar.clock') }} </span>
    </template>
  </div>
</template>

<script lang="ts">
export interface TimeStampProps {
  /**
   * From date/time string or date object.
   */
  from?: string | Date | null;

  /**
   * To date/time string or date object.
   */
  to?: string | Date | null;

  /**
   * The desired date/time format for from/to.
   */
  format?: string;
}
</script>

<script lang="ts" setup>
import DateTime from '@/components/common/DateTime.vue';

// Define Component
const props = withDefaults(defineProps<TimeStampProps>(), {
  format: 'shortTime'
});
</script>

<style scoped>
.timestamp {
  @apply text-sm font-semibold;
}
</style>
