import type { LocaleObject } from 'yup';

export default function (t: (key: string, options?: any) => string): LocaleObject {
  return {
    mixed: {
      required: (params) => t('validation.mixed.required', { path: t(params.path) }),
      default: (params) => t('validation.mixed.default', { path: t(params.path) }),
      defined: (params) => t('validation.mixed.defined', { path: t(params.path) }),
      notNull: (params) => t('validation.mixed.notNull', { path: t(params.path) }),
      oneOf: (params) => t('validation.mixed.oneOf', { path: t(params.path), values: params.values }),
      notOneOf: (params) => t('validation.mixed.notOneOf', { path: t(params.path), values: params.values }),
      notType: (params) =>
        t('validation.mixed.notType', {
          path: t(params.path),
          type: params.type,
          value: params.value,
          originalValue: params.originalValue,
          isCast: params.originalValue != null && params.originalValue !== params.value
        })
    },
    number: {
      min: (params) => t('validation.number.min', { path: t(params.path), min: params.min }),
      max: (params) => t('validation.number.max', { path: t(params.path), max: params.max }),
      lessThan: (params) => t('validation.number.lessThan', { path: t(params.path), less: params.less }),
      moreThan: (params) => t('validation.number.moreThan', { path: t(params.path), more: params.more }),
      //notEqual: '${path} darf nicht gleich sein mit "${notEqual}"',
      positive: (params) => t('validation.number.positive', { path: t(params.path) }),
      negative: (params) => t('validation.number.negative', { path: t(params.path) }),
      integer: (params) => t('validation.number.integer', { path: t(params.path) })
    },
    date: {
      min: (params) => t('validation.date.min', { path: t(params.path), min: params.min }),
      max: (params) => t('validation.date.max', { path: t(params.path), max: params.max })
    },
    string: {
      length: (params) => t('validation.string.length', { path: t(params.path), length: params.length }),
      min: (params) => t('validation.string.min', { path: t(params.path), min: params.min }),
      max: (params) => t('validation.string.max', { path: t(params.path), max: params.max }),
      matches: (params) => t('validation.string.matches', { path: t(params.path), regex: params.regex }),
      email: (params) => t('validation.string.email', { path: t(params.path), regex: params.regex }),
      url: (params) => t('validation.string.url', { path: t(params.path), regex: params.regex }),
      trim: (params) => t('validation.string.trim', { path: t(params.path) }),
      lowercase: (params) => t('validation.string.lowercase', { path: t(params.path) }),
      uppercase: (params) => t('validation.string.uppercase', { path: t(params.path) }),
      uuid: (params) => t('validation.string.uuid', { path: t(params.path), regex: params.regex })
    },
    object: {
      noUnknown: (params) => t('validation.object.noUnknown', { path: t(params.path) })
    },
    array: {
      min: (params) => t('validation.array.min', { path: t(params.path), min: params.min }),
      max: (params) => t('validation.array.max', { path: t(params.path), max: params.max }),
      length: (params) => t('validation.array.length', { path: t(params.path), length: params.length })
    },
    boolean: {
      isValue: (params) => t('validation.boolean.isValue', { path: t(params.path) })
    }
  };
}
