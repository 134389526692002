<template>
  <div class="header" v-resize="closeMenuOnResize">
    <div class="header-top">
      <HeaderHeadline :name="title" :go-back="goBack" />
      <div id="app-header-submenu" class="header-submenu">
        <BackButton :label="$t('application.back')" @click.prevent="goBack ? $router.back() : $router.push(goBack)" v-if="goBack" />
        <SecondaryNavigation v-else />
      </div>
    </div>

    <div class="header-menu">
      <ShadowButton
        :icon="IconMenu"
        class="menu-action menu-mobile-toggle"
        @click="emits('open-menu-mobile')"
      />

      <div class="flex items-center gap-4">
        <ShadowButton :icon="IconMagnify" @click.prevent="emits('open-search')" />

        <ShadowButton
          :icon="IconChat"
          @click="$router.push({ name: 'messages-list', params: { type: 'system' } })"
          class="message-button"
          v-if="messagesNumber == 0" />
       
        <ShadowButton
          :icon="IconChat"
          @click="$router.push({ name: 'messages-list', params: { type: 'system' } })"
          class="message-button"
          v-badge.primary="messagesNumber > 99 ? '99+' : messagesNumber"
          v-else />

        <ShadowButton
          :icon="IconCalendarBlank"
          @click.prevent="emits('open-calendar')"
          :class="$router.currentRoute.value.name == '6100' ? 'xl:!hidden' : ''"
        />

        <div class="profile-menu-toggle" @click="toggle">
          <img v-if="compactProfile?.photo" :src="compactProfile.photo" />
          <PrimeSkeleton class="!w-full !h-full" />
        </div>

        <HeaderProfileMenu ref="profileMenu" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InfoType } from '@/types/contracts/generated/models/infoType';

import IconCalendarBlank from '~icons/mdi/calendar-blank';
import IconChat from '@/components/icons/IconChat.vue';
import IconMagnify from '~icons/mdi/magnify';
import IconMenu from '~icons/mdi/menu';

import {computed, ref, watch} from 'vue';
import { storeToRefs } from 'pinia';
import PrimeSkeleton from 'primevue/skeleton';
import { useRouter } from 'vue-router';

import BackButton from '@/components/buttons/BackButton.vue';
import ShadowButton from '@/components/buttons/ShadowButton.vue';
import SecondaryNavigation from '@/components/page/SecondaryNavigation.vue';
import HeaderProfileMenu from '@/components/page/header/HeaderProfileMenu.vue';
import HeaderHeadline from '@/components/page/header/HeaderHeadline.vue';
import { useInfoStore } from '@/stores/info';
import { usePersonStore } from '@/stores/person';
import { useSitemapStore } from '@/stores/sitemap';

// Define Component
const emits = defineEmits<{
  (ev: 'open-search'): void;
  (ev: 'open-calendar'): void;
  (ev: 'open-menu-mobile'): void;
}>();

// Stores
const { getType } = useInfoStore();
const router = useRouter();

// States
const { compactProfile } = storeToRefs(usePersonStore());
const { activeNode, mainMenu } = storeToRefs(useSitemapStore());
const title = ref<string>();
const goBack = ref<string|boolean>(false);
const profileMenu = ref<InstanceType<typeof HeaderProfileMenu>>();
const messagesNumber = computed(() => {
  return (getType(InfoType.System)?.unread ?? 0) + (getType(InfoType.News)?.unread ?? 0) + (getType(InfoType.Message)?.unread ?? 0);
})

/**
 * Toggle Profile Menu
 * @param event 
 */
const toggle = (event: MouseEvent) => {
  if (profileMenu.value) {
    profileMenu.value.primeMenu?.toggle(event);
  }
};

/**
 * Close Profile Menu on Resize
 */
function closeMenuOnResize() {
  if (profileMenu.value) {
    profileMenu.value.primeMenu?.hide();
  }
}

/**
 * Watch Route Changes
 */
watch(router.currentRoute, (newValue) => {
  const nodeId = (newValue.meta?.activeNode || !!newValue.meta?.goBack) as string | boolean;
  goBack.value = typeof nodeId == 'string' ? `/node/${nodeId}` : nodeId;

  // Find current parent
  // @todo Find a better, data-oriented solution to get the desired title.
  const currentNodeId = activeNode.value?.nodeid == 6135 ? 6135 : activeNode.value?.path?.filter((nodeid: number) => nodeid !== 6099)[0];
  const currentNode = mainMenu.value.find(entry => entry.nodeid === currentNodeId);
  const currentTitle = currentNode ? currentNode.i18n || currentNode.i18n2 || void 0 : void 0;
  if (currentTitle) {
    title.value = currentTitle;
  } else {
    if (typeof newValue.meta?.extended != 'undefined') {
      title.value = newValue.meta.extended.i18n || newValue.meta.extended.i18n2 || (newValue.meta.title || newValue.name || '') as string;
    } else {
      title.value = (newValue.meta.title || newValue.name || '') as string;
    }
  }
}, { immediate: true });
</script>

<style scoped>
.header {
  @apply h-full flex flex-col-reverse justify-between px-4 pt-4;
  @apply max-w-full;

  @screen lg {
    @apply flex-row items-center px-8;
  }
}

.header-top {
  @apply flex flex-1 items-center py-4;
  
  @screen lg {
    @apply flex-col items-start p-0 ;
    max-width: calc(100% - 210px);
  }

  & .header-submenu {
    @apply h-auto w-full hidden;
    
    @screen lg {
      @apply h-12 flex;
    }
  }
}

.header-title {
  @apply flex flex-col gap-2 items-start w-full flex-1;

  & .title-name {
    @apply font-bold m-0 leading-8;
    @apply text-zinc-600;
  }

  & .title-goback {
    @apply inline-flex items-center gap-1.5 text-sm font-semibold px-2 py-1 -ml-2 rounded;
    @apply duration-300 ease-in-out transition-colors;
    @apply text-primary-500;

    &:hover {
      @apply bg-primary-500/10;
    }
  }
}

.header-menu {
  @apply flex flex-row justify-between gap-4 ;

  & .menu-mobile-toggle {
    @apply lg:hidden;
  }

  @screen lg {
    @apply mb-4;
  }
}

.profile-menu {
  &-toggle {
    @apply hidden lg:block w-10 h-10 overflow-hidden rounded-full cursor-pointer bg-zinc-100;
  }

  &-toggle img {
    @apply transition-transform duration-300 ease-in-out;
  }

  &-toggle:hover img {
    transform: scale(1.05);
  }
}

:deep(.message-button .p-badge) {
  @apply aspect-square flex justify-center items-center rounded-full px-0;
  @apply text-xs;
  line-height: 1.325rem;
  z-index: 50;
}
</style>
