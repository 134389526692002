<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.size || 32"
    :height="props.size || 32"
    :fill="props.fill || 'currentColor'"
    viewBox="0 0 48 48"
  >
    <path
      d="M37.068,28.05a22.766,22.766,0,0,1-7.154-1.142,2.016,2.016,0,0,0-2.044.481L23.461,31.8A30.209,30.209,0,0,1,10.254,18.611l4.409-4.429a1.967,1.967,0,0,0,.5-2.024A22.766,22.766,0,0,1,14.022,5a2.01,2.01,0,0,0-2-2H5A2.01,2.01,0,0,0,3,5,34.065,34.065,0,0,0,37.068,39.072a2.01,2.01,0,0,0,2-2V30.054a2.01,2.01,0,0,0-2-2m-30-21.042h3.006a25.19,25.19,0,0,0,.942,5.19L8.591,14.623A30.037,30.037,0,0,1,7.068,7.008m28,28a29.863,29.863,0,0,1-7.615-1.523l2.4-2.425a24.287,24.287,0,0,0,5.21.922V35M21.036,3V23.04l6.012-6.012H39.072V3H21.036M35.064,13.02H25.044V7.008h10.02Z"
      transform="translate(2.963 2.964)"
    />
  </svg>
</template>

<script lang="ts">
export interface IconProps {
  /**
   * Icon Size.
   */
  size?: number | string;

  /**
   * Icon Fill Color.
   */
  fill?: string;
}
</script>

<script lang="ts" setup>
// Define Component
const props = defineProps<IconProps>();
</script>
