<template>
  <div class="flex-1 h-full overflow-y-auto search-results">
    <div v-if="searchResult" class="h-full results-wrapper">
      <div ref="scrollContainer" class="flex flex-col h-full gap-8 py-4 m-auto overflow-y-auto rounded bg-zinc-500/5">
        <div v-if="searchResult.length == 0" class="flex flex-col items-center justify-center h-full">
          <IllustrationSearchNotFound class="w-60 h-60" />
          <p class="text-lg font-bold lg:text-xl text-zinc-500">{{ t('primevue.emptySearchMessage') }}</p>
        </div>
        <SearchResult
          v-else
          v-for="result of searchResult"
          :key="result.headline"
          :searchResult="result"
          :term="search"
        />
      </div>
    </div>
    <div v-else class="flex items-center justify-center flex-1 h-full p-4 !pt-0 lg:p-8">
      <div v-if="props.loading" class="flex flex-col w-full h-full gap-4">
        <PrimeSkeleton v-for="index in 3" :key="index" />
      </div>
      <div v-else class="flex flex-col items-center">
        <!-- @todo categoryValue moved to SearchCategories, how to get it here?  -->
        <!-- <div v-if="categoryValue.length === 0" class="text-danger-100">
              {{ t('search.no_category_selected') }}
            </div> -->
        <IllustrationSearchPlaceholder />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useSearchStore } from '@/stores/search';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import PrimeSkeleton from 'primevue/skeleton';
import SearchResult from './SearchResult.vue';
import IllustrationSearchPlaceholder from '@/components/illustrations/SearchPlaceholderIllustration.vue';
import IllustrationSearchNotFound from '@/components/illustrations/SearchNotFoundIllustration.vue';

const props = defineProps({
  loading: Boolean
});

const { t } = useI18n();
const { search, searchResult } = storeToRefs(useSearchStore());
const scrollContainer = ref(null);
</script>
