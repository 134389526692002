<template>
  <PrimeInputText
    :name="props.name ? (props.name as string) : void 0"
    :disabled="props.disabled"
    :required="props.required"
    :class="{ 'p-invalid': props.invalid }"
    v-model="value"
  />
</template>

<script lang="ts">
import type { MaybeRef } from 'vue';

export interface InputFieldProps {
  /**
   * A unique field id, may already be generated by the parent FormControl component.
   */
  id: string;

  /**
   * The desired field value.
   */
  modelValue?: string;

  /**
   * The desired field name.
   */
  name?: MaybeRef<string> | string;

  /**
   * The field disabled state.
   */
  disabled?: boolean;

  /**
   * The field required state.
   */
  required?: boolean;

  /**
   * The field invalid state.
   */
  invalid?: boolean;
}

export interface InputFieldEmits {
  /**
   * Update model value handler.
   */
  (ev: 'update:modelValue', value: string): void;
}
</script>

<script lang="ts" setup>
import PrimeInputText from 'primevue/inputtext';
import { computed } from 'vue';

// Define Component
const props = defineProps<InputFieldProps>();
const emits = defineEmits<InputFieldEmits>();

// States
const value = computed({
  get() {
    return props.modelValue || '';
  },
  set(value) {
    emits('update:modelValue', value);
  }
});
</script>

<style scoped>
.p-inputtext {
  @apply w-full;
}
</style>
