import type { KompaktProfilViewModel } from '@/types/contracts/generated/models/kompaktProfilViewModel';
import type { PersonDto } from '@/types/contracts/generated/models/personDto';

import { defineStore } from 'pinia';
import { usePerson } from '@/composables/http/use-person';
import { useBcwServices } from '@/composables/http/use-bcw-services';

const { getKompaktProfil } = useBcwServices();

interface State {
  teachers: Map<number, PersonDto>;
  compactProfile: KompaktProfilViewModel;
}

export const usePersonStore = defineStore('person', {
  state: (): State => ({
    teachers: new Map<number, PersonDto>(),
    compactProfile: {}
  }),
  getters: {
    getById:
      (state) =>
      (id: number): PersonDto | undefined => {
        return state.teachers.get(id);
      }
  },
  actions: {
    fetchById(id: number) {
      const { getPerson } = usePerson();
      return getPerson(id).then((value) => this.teachers.set(id, value));
    },

    fetchCompactProfile() {
      getKompaktProfil()
        .then((data) => {
          this.compactProfile = data;
        })
        .catch((err) => console.error(err));
    }
  }
});
