
/// @ts-ignore
import { crypto as _crypto } from 'polyfill-crypto-methods';

const _global = self || window || global || globalThis;

/**
 * The Polyfill Shim does not work properly.
 */
if ("crypto" in _global) {
  if (!("getRandomValues" in _global.crypto)) {
    (_global.crypto as any).getRandomValues = _crypto.getRandomValues;
  }
  if (!("randomBytes" in _global.crypto)) {
    (_global.crypto as any).randomBytes = _crypto.randomBytes;
  }
  if (!("randomUUID" in _global.crypto)) {
    (_global.crypto as any).randomUUID = _crypto.randomUUID;
  }
} else {
  (_global as any).crypto = {
    getRandomValues: _crypto.getRandomValues,
    randomBytes: _crypto.randomBytes,
    randomUUID: _crypto.randomUUID,
  };
}

/**
 * Polyfill (add|remove)EventListener on MediaQueryList
 */
if ('MediaQueryList' in _global) {
  if (typeof MediaQueryList.prototype.addEventListener == 'undefined') {
    (MediaQueryList.prototype as any).addEventListener = typeof MediaQueryList.prototype.addListener;
  }
  if (typeof MediaQueryList.prototype.removeEventListener == 'undefined') {
    (MediaQueryList.prototype as any).removeEventListener = typeof MediaQueryList.prototype.removeListener;
  }
} else if (typeof window['matchMedia'] == 'function') {
  const _proto = (matchMedia('(max-width: 1024px)') as any).__proto__;
  
  if (typeof _proto.addEventListener == 'undefined') {
    (_proto as any).addEventListener = _proto.addListener;
  }
  if (typeof _proto.removeEventListener == 'undefined') {
    (_proto as any).removeEventListener = _proto.removeListener;
  }
}
