<template>
  <div class="loading-indicator">
    <div class="loading-spinner" :class="sizeMap.get(props.size)">
      <div class="spinner-outer">
        <div class="spinner-inner"></div>
      </div>
    </div>
    <div class="loading-label" v-if="props.label">{{ props.label }}</div>
  </div>
</template>

<script lang="ts">
export interface LoadingFeedbackProps {
  /**
   * Additional label, shown below the loading spinner.
   */
  label?: string;
  size?: "small" | "medium" | "large";
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'LoadingFeedback'
}
</script>

<script lang="ts" setup>
// Define Component
const props = withDefaults(defineProps<LoadingFeedbackProps>(), {
  size: "large"
});

const sizeMap = new Map<LoadingFeedbackProps["size"] | undefined, string>([
  ['large', 'loading-spinner--large'],
  ['medium', 'loading-spinner--medium'],
  ['small', 'loading-spinner--small'],
]);
</script>

<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  66% {
    transform: rotate(730deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

.loading-indicator {
  @apply relative flex flex-col items-center;
}

.loading-spinner {
  @apply size-16 relative;

  &--small, &--small .spinner-outer, &--small .spinner-inner {
    @apply size-8;
  }
  &--medium, &--medium .spinner-outer, &--medium .spinner-inner {
    @apply size-12;
  }
  &--large, &--large .spinner-outer, &--large .spinner-inner {
    @apply size-16;
  }
}

.spinner-outer {
  @apply w-16 h-16 relative border-4 border-solid rounded-full;
  @apply bg-transparent border-zinc-200;
}

.spinner-inner {
  @apply w-16 h-16 absolute border-4 border-solid rounded-full -mt-1 -ml-1;
  @apply border-primary-600;
  clip-path: polygon(50% 50%, 80% 100%, 20% 100%);
  animation: rotate infinite forwards 3s ease-in-out;
}

.loading-label {
  @apply font-semibold text-center mt-4;
}
</style>
