<template>
  <ul class="page-navi">
    <li v-for="route in mainMenu.filter((entry) => !entry.profil)" :key="route.nodeid" class="navi-item">
      <a
        :href="`/node/${route.includeid ?? route.nodeid}`"
        :class="['item-link', active(route.nodeid) ? 'active' : '']"
        v-tooltip.right="{ content: route.i18n, theme: 'navigation', delay: { show: '400', hide: '0' } }"
        @click.prevent="$router.push(`/node/${route.includeid ?? route.nodeid}`)"
      >
        <component :is="route.image ? route.image : 'IconTag'" />
        <span class="link-text">{{ route.nodeid }}</span>
      </a>
    </li>
  </ul>

  <div class="page-version">
    <span class="version-text">{{ campusVersion }}</span>
  </div>

  <div class="page-line"></div>

  <a href="/" class="page-logo" @click.prevent="$router.push('/')">
    <img :src="logoBCW" alt="BCW Logo" v-if="theme === 'BCW'" />
    <img :src="logoFOM" alt="FOM Logo" v-else />
  </a>
</template>

<script lang="ts" setup>
import logoBCW from '@/assets/logos/Logo-BCW.png';
import logoFOM from '@/assets/logos/Logo-FOM.svg';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useSitemapStore } from '@/stores/sitemap';

// Stores
const { activeNode, mainMenu, theme } = storeToRefs(useSitemapStore());
const router = useRouter();

// States
const campusVersion = ref<string>(CAMPUS_VERSION || 'dev');

/**
 * Check for current node id / route.
 * @param nodeId
 */
function active(nodeId: number | undefined) {
  if (!nodeId) {
    return false;
  }

  const node = activeNode.value || ({} as any);
  const nodePath = node?.path || [];
  if (nodePath.includes(nodeId) || (node.includeid || node.nodeid || null) === nodeId) {
    return true;
  } else {
    const activeNode = router.currentRoute.value.meta.activeNode || null;
    return activeNode === nodeId;
  }
}
</script>

<style scoped>
.page-navi {
  @apply flex flex-col justify-between w-full m-0 px-0 py-6;
}

.navi-item {
  @apply flex flex-col w-full items-center;
}

.item-link {
  @apply relative outline-none shadow-none border-0 flex items-center justify-center;
  @apply duration-300 ease-in-out transition-colors;
  @apply bg-transparent text-white;
  width: 80px;
  height: 80px;

  &::before {
    @apply top-4 bottom-4 left-0 w-1 absolute -translate-x-full rounded-r-full;
    @apply duration-300 ease-in-out transition-transform;
    @apply bg-accent-100;
    content: '';
  }

  &:hover {
    /* @todo subtle hover state */
    /*@apply bg-primary-600/50;*/
  }

  &.active {
    @apply text-accent-100;

    &::before {
      @apply translate-x-0;
    }
  }

  @screen md {
    & .link-text {
      @apply hidden;
    }
  }
}

.page-version {
  @apply flex mt-auto mb-2 justify-center;

  & .version-text {
    @apply text-sm font-semibold text-white text-center;
  }
}

.page-line {
  @apply w-full h-px bg-white;
}

.page-logo {
  @apply outline-none shadow-none border-0 p-0 cursor-pointer;
  @apply border-white bg-transparent;
  @apply duration-300 ease-in-out transition-colors;

  img {
    @apply block m-0;
    width: 80px;
    height: 80px;
  }
}
</style>
