<template>
  <div
    class="btn-group"
    :class="[$props.gap ? `btn-group-${$props.gap}` : '', $props.connected ? `btn-group-connected` : '']"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
export interface ButtonGroupProps {
  /**
   * The desired gap size, should fit the used button size (small -> small).
   */
  gap?: 'small' | 'normal' | 'large';

  /**
   * Connected Button Mode.
   */
  connected?: boolean;
}

export interface ButtonGroupSlots {
  /**
   * Default Button Slot
   */
  default(): any;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'ButtonGroup'
}
</script>

<script lang="ts" setup>
defineProps<ButtonGroupProps>();
defineSlots<ButtonGroupSlots>();
</script>

<style scoped>
.btn-group {
  @apply w-full flex flex-row gap-3 items-center justify-end;

  &.btn-group-small {
    @apply gap-1.5;
  }

  &.btn-group-large {
    @apply gap-5;
  }
}

.btn-group.btn-group-connected :deep(.p-button:not(:first-child)) {
  @apply rounded-l-sm;
}

.btn-group.btn-group-connected :deep(.p-button:not(:last-child)) {
  @apply rounded-r-sm;
}
</style>
