import { Problem } from '@/models/error/problem';
import { HttpStatusCode } from 'axios';

export interface ForbiddenProblem extends Problem {}

export class ForbiddenProblem extends Problem {
  constructor(message: string, title: string, instance: string, baseError: Error) {
    super(message, HttpStatusCode.Forbidden, title, instance, baseError);
  }
}
