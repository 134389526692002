/**
 * [netzfactor] BCW Core Services API (netzfactor)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * Contact: app@netzfactor.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InfoType = 'MESSAGE' | 'NEWS' | 'SYSTEM' | 'All';

export const InfoType = {
    Message: 'MESSAGE' as InfoType,
    News: 'NEWS' as InfoType,
    System: 'SYSTEM' as InfoType,
    All: 'All' as InfoType
};

