<template>
  <BaseDialog :title="$t('dialog.app_update.header')" v-model:visible="showAppUpdateDialog" :closable="false" :escapable="false" :dismissable="false">
    <div class="mb-6">
      {{ $t('dialog.app_update.message') }}
    </div>
    <div class="flex w-full">
      <PrimaryButton :label="$t('dialog.app_update.confirm_button')" @click="onAction" class="!w-full" />
    </div>
  </BaseDialog>
</template>

<script setup lang="ts">
import BaseDialog from "@/components/dialogs/BaseDialog.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import {useMiscStore} from "@/stores/misc";
import {storeToRefs} from "pinia";

const {showAppUpdateDialog} = storeToRefs(useMiscStore());

const onAction = () => {
  window.location.reload();
}

</script>

<style scoped>

</style>