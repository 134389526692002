<template>
  <nav ref="nav" class="secondary-nav" v-if="topMenu && width >= 1024 && items.length > 1">
    <ul ref="menu" class="nav-menu">
      <li v-for="(item, idx) of items" :key="item.nodeid || idx"
        ref="menuItems"
        class="menu-item"
        :class="{
          'item-active': activeNodes.includes(item.includeid ?? (item.nodeid as number)),
          'item-parent-active': activeNode?.parentid == item?.nodeid,
        }">
        <a :href="getPath(item)" class="item-link" @click.prevent="$router.push(getPath(item))">
          <span class="link-text">{{ item.i18n }}</span>
        </a>
      </li>

      <li v-if="hiddenItems.length > 0" aria-controls="overflowNav" aria-haspopup="true" class="menu-item menu-item-hidden" 
        :class="hiddenItems.find(item => item.nodeid == activeNode?.nodeid || item.includeid == activeNode?.nodeid) ? 'item-active' : ''" 
        data-id="menuMore" label="Toggle" ref="hiddenMenuItem">
        <a href="#" class="item-link" @click.prevent="(ev) => hiddenMenu ? hiddenMenu.toggle(ev) : void 0">
          <span class="link-text">{{ $t('greedy.nav') }}</span>
          <IconChevronDown class="ml-1" />
        </a>
      </li>

      <Menu ref="hiddenMenu" id="overflowNav" class="!min-w-[225px]" :model="hiddenMenuItems" popup>
        <template #item="{ item, props }">
          <RouterLink v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a :href="href" v-bind="props.action" @click="navigate">
              <span class="text-sm">{{ item.label }}</span>
            </a>
          </RouterLink>
          <a v-else :href="item.url" :target="item.target" v-bind="props.action">
            <span class="text-sm">{{ item.label }}</span>
          </a>
        </template>
      </Menu>

      <span ref="indicator" class="menuIndicator" :style="indicatorStyles ? indicatorStyles : {}"></span>
    </ul>
  </nav>
</template>

<script lang="ts">
// Type Definitions
</script>

<script lang="ts" setup>
import type { MenuItem } from 'primevue/menuitem';
import type { ExtendedMenuNodeDto } from '@/models/ExtendedMenuNodeDto';

import { useWindowSize, watchThrottled } from '@vueuse/core';
import IconChevronDown from '~icons/mdi/chevron-down';
import { storeToRefs } from 'pinia';
import Menu from 'primevue/menu';
import { computed, inject, nextTick, ref, watch } from 'vue';

import { useSitemapStore } from '@/stores/sitemap';

// Stores
const { topMenu, activeNode } = storeToRefs(useSitemapStore());

// States
const { width } = useWindowSize();
const activeNodes = ref<number[]>([]);
const items = ref<ExtendedMenuNodeDto[]>([]);

const nav = ref<HTMLElement>();
const menu = ref<HTMLElement>();
const menuItems = ref<HTMLLIElement[]>();
const indicator = ref<HTMLElement>();
const indicatorStyles = inject<{ width: string; transform: string }>('menu-indicator');

const hiddenMenu = ref<InstanceType<typeof Menu>>();
const hiddenMenuItem = ref<HTMLLIElement>();
const hiddenItems = ref<ExtendedMenuNodeDto[]>([]);

// Convert extended menu nodes into primevue's menu items
const hiddenMenuItems = computed<MenuItem[]>(() => {
  return [...hiddenItems.value].map(item => {
    return {
      label: item.i18n,
      route: { path: getPath(item) }
    }
  }).reverse();
});

/**
 * Watch Active Node
 */
watch(activeNode, (newValue) => {
  if (!newValue) {
    activeNodes.value = [];
    hiddenItems.value = [];
    items.value = [];
  } else {
    const node = newValue as any;
    activeNodes.value = [node.includeid ?? node.nodeid ?? 0, ...[node.path ? node.path : []]].filter(nodeid => nodeid != 6099).flat();
    hiddenItems.value = [];
    items.value = JSON.parse(JSON.stringify(topMenu.value)) || [];
  }
}, { immediate: true });

/**
 * Handle active item
 */
watchThrottled(activeNode, async () => {
  await nextTick(calculateIndicator);
}, { immediate: true, throttle: 100 });

/**
 * Watch resize actions to handle greedy nav
 */
watch(width, async () => {
  await nextTick(greedyNav);
  await nextTick(calculateIndicator);
});

/**
 * Watch items changes to handle greedy nav
 */
watch(items, async () => {
  await nextTick(greedyNav);
  await nextTick(calculateIndicator);
}, { immediate: true });

/**
 * Get Route path
 * @param route
 */
function getPath(route: ExtendedMenuNodeDto) {
  return `/node/${route.includeid ?? route.nodeid}`;
}

/**
 * Calculate Indicator position and width
 */
function calculateIndicator() {
  if (!indicatorStyles || !indicator.value || !menu.value) {
    return;
  }

  let activeItem = menu.value.querySelector('.item-active');
  if (!activeItem) {
    activeItem = menu.value.querySelector('.item-parent-active');
  }
  if (activeItem instanceof HTMLLIElement) {
    indicatorStyles.width = `${activeItem.offsetWidth}px`;
    indicatorStyles.transform = `translateX(${activeItem.offsetLeft}px)`;
  } else {
    indicatorStyles.width = `0px`;
  }
}

/**
 * Greedy Nav Handling
 */
function greedyNav() {
  if (!nav.value || !menu.value || !menuItems.value) {
    return;
  }

  let availableSpace = nav.value.offsetWidth;
  let usedSpace = menu.value.offsetWidth

  if (availableSpace < usedSpace) {
    while (availableSpace < usedSpace && items.value.length > 0) {
      const item = items.value.pop();
      const menuItem = menuItems.value.pop();
      if (!item || !menuItem) {
        break;
      }

      usedSpace = usedSpace - menuItem.offsetWidth;
      hiddenItems.value.push(item);
    }
  } else if (availableSpace > usedSpace) {
    while (availableSpace > usedSpace && hiddenItems.value.length > 0) {
      const item = hiddenItems.value[hiddenItems.value.length - 1];
      if (!item) {
        break;
      }

      let calculatedSpace = usedSpace + calculateWidth(item);

      // Subtract "More" menu item, when only one hidden item is left
      if (hiddenItems.value.length == 1 && hiddenMenuItem.value) {
        calculatedSpace -= hiddenMenuItem.value.offsetWidth;
      }

      // Check whether the available space is enough or not
      if (availableSpace < calculatedSpace) {
        break;
      }

      items.value.push(hiddenItems.value.pop() as any);
    }
  }
}

/**
 * Calculate Width
 * @param item 
 */
function calculateWidth(item: ExtendedMenuNodeDto) {
  const element = document.createElement('a')
  element.className = 'flex items-end pb-3 text-gray-700';
  element.innerText = item.i18n as string;

  const navItem = document.createElement('li');
  navItem.className = 'break-keep whitespace-nowrap absolute opacity-0';
  navItem.append(element);

  if (menu.value) {
    if (menu.value.children.length > 0) {
      menu.value.insertBefore(navItem, menu.value.children[0]);
    } else {
      menu.value.append(navItem);
    }
  }

  const width = navItem.offsetWidth + parseInt(getComputedStyle(navItem.parentElement as HTMLUListElement).gap)
  navItem.remove();
  return width;
}
</script>

<style scoped>
.secondary-nav {
  @apply flex mt-3 mb-2.5 w-full h-full;
}

.nav-menu {
  @apply flex flex-row m-0 p-0 gap-6 relative items-start justify-start;

  & .menuIndicator {
    @apply block absolute left-0 w-0 translate-x-0 bg-primary-500;
    height: 3px;
    bottom: 3px;
    transition:
      transform 0.5s,
      width 0.5s;
  }
}

.menu-item {
  @apply flex m-0 p-0;

  &.item-active .item-link {
    & .link-text {
      @apply text-primary-700;
    }
  }

  &.item-greedy {
    @apply text-gray-700 cursor-pointer;

    @screen lg {
      @apply py-0;
    }
  }
}

.item-link {
  @apply h-12 mt-0.5 flex items-center relative overflow-hidden;
  border-bottom-width: 2px;

  & .link-text {
    @apply text-base whitespace-nowrap;
    @apply duration-300 ease-in-out transition-colors;
    @apply text-zinc-700;
  }

  &:hover {
    @apply no-underline;

    & .link-text {
      @apply text-primary-800;
    }
  }
}
</style>
