import { useVModel } from '@vueuse/core';
import { useField } from 'vee-validate';
import { computed, toRef, watch } from 'vue';

import { isEqual } from '@/utils/is-equal';

export function useFormField<T>(
  props: any,
  emit: (e: any, v: any) => void,
  controlled = true,
  modelKey = 'modelValue'
) {
  const { value, errorMessage, setValue, meta } = useField<T>(toRef(props, 'inputName'), undefined, {
    initialValue: props.modelValue,
    syncVModel: false,
    controlled
  });

  const data = useVModel(props, modelKey, emit);
  watch(data, (newValue) => {
    if (!isEqual(newValue, value.value)) {
      setValue(newValue);
    }
  });

  watch(value, (newValue) => {
    if (!isEqual(newValue, data.value)) {
      data.value = newValue;
    }
  });

  const hasError = computed(() => {
    return errorMessage.value && meta.touched;
  });

  return {
    value,
    hasError,
    errorMessage
  };
}
