import { useCampusMiscApi } from '@/composables/http/use-campus-misc-api';
import { useResource } from '@/composables/http/use-resource';
import { useDownloadsStore } from '@/stores/downloads';

export function useDownload() {
  const { getDownloadToken } = useCampusMiscApi();
  const { makeGetRequest } = useResource();

  const getDownloadItemUrl = async (url: string) => getDownloadToken().then((ticket) => `${url}${url.includes('?') ? '&' : '?'}token=${ticket}`);

  const downloadItem = async (url: string) => {
    const iOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) 
             || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    if (iOS) {
      const downloadStore = useDownloadsStore();
      downloadStore.requestDialog(getDownloadItemUrl(url));
    } else {
      const result = await getDownloadItemUrl(url);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = result;
      a.target = '_blank';
      a.click();
      a.remove();
      return Promise.resolve();
    }
  }

  const displayImage = async (url: string): Promise<string> =>
    getDownloadToken().then((ticket) => `${url}${url.includes('?') ? '&' : '?'}token=${ticket}`);
  const displayItemData = async (url: string) =>
    getDownloadToken().then((ticket) => makeGetRequest(`${url}`, { params: { token: ticket } }));

  return {
    getDownloadItemUrl,
    downloadItem,
    displayImage,
    displayItemData
  };
}
