<template>
  <ButtonStd v-bind="props" @click="(ev) => emits('click', ev)" severity="danger" />
</template>

<script lang="ts">
import type { ButtonProps, ButtonEmits } from '@/components/buttons/ButtonStd.vue';

export interface DangerButtonProps extends Omit<ButtonProps, 'severity'> {}

export interface DangerButtonEmits extends ButtonEmits {}

// Default Export, used for IDE auto-importing only
export default {
  name: 'DangerButton'
}
</script>

<script lang="ts" setup>
import ButtonStd from '@/components/buttons/ButtonStd.vue';

// Define Component
const props = withDefaults(defineProps<DangerButtonProps>(), {
  iconPos: 'left'
});
const emits = defineEmits<DangerButtonEmits>();
</script>

<style scoped>
/* Stylings */
</style>
