/**
 * [netzfactor] BCW Core Services API (netzfactor)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * Contact: app@netzfactor.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Kommunikationsfeldtyp = 'eMail' | 'Fax' | 'Messenger' | 'Mobile' | 'Phone' | 'URL' | 'WebProfil';

export const Kommunikationsfeldtyp = {
    EMail: 'eMail' as Kommunikationsfeldtyp,
    Fax: 'Fax' as Kommunikationsfeldtyp,
    Messenger: 'Messenger' as Kommunikationsfeldtyp,
    Mobile: 'Mobile' as Kommunikationsfeldtyp,
    Phone: 'Phone' as Kommunikationsfeldtyp,
    Url: 'URL' as Kommunikationsfeldtyp,
    WebProfil: 'WebProfil' as Kommunikationsfeldtyp
};

