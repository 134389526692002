import type { SocketTicket } from '@/models/SocketTicket';
import { useResource } from '@/composables/http/use-resource';

const baseUrl = `${import.meta.env.VITE_WS_TICKET_CAMPUS}`;
export function useCampusWebsocketTicket() {
  const { makeGetRequest } = useResource();

  const fetchTicket = async (): Promise<SocketTicket> => makeGetRequest(`${baseUrl}`);

  return {
    fetchTicket
  };
}
