<template>
  <BaseDialog :title="loading ? $t(strings.titlePrepared) : $t(strings.title) " v-model:visible="visible" @hide="onClose">
    <div class="flex w-full">
      <PrimaryButton :label="loading ? $t(strings.loading): $t(strings.action) " :loading="loading" @click="onOpen" class="!w-full" />
    </div>
  </BaseDialog>
</template>

<script lang="ts">
// Type Definitions
</script>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import { useDownloadsStore } from '@/stores/downloads';

// Stores
const downloadStore = useDownloadsStore();

// State
const { error, url, visible, strings } = storeToRefs(downloadStore);
const loading = computed<boolean>(() => url.value instanceof Promise);

/**
 * Open URL
 */
function onOpen() {
  if (typeof url.value == 'string') {
    window.open(url.value, '_blank');
  }
}

/**
 * Dialog has been closed
 */
function onClose() {
  url.value = null;
  error.value = null;
}
</script>

<style scoped>
.p-button {
  @apply !w-full;
}
</style>
